/* eslint-disable no-undef */
import buildUrlUtm from '@/utils/buildUrlUtm';

const brandColor1 = '#CEFDE5';
const brandColor2 = '#EFF4FB';

const personalizedColors = {
  darkBlueBackground: '#00A868',
  whiteText: '#FFFFFF',
  entryPointBackground: '#00AB63',
  lighterBackground: '#ecedf1',
  primaryButtonBackground: '#14aa4b',
  primaryButtonColor: '#FFFFFF',
  secondaryButtonBackground: '#013920',
  secondaryButtonColor: '#FFFFFF',
};

const brandMessageBubbleColors = bgColor => {
  return {
    Bubble: {
      background: bgColor,
      color: '#1D232A',
    },
    Avatar: {
      display: 'none',
      background: bgColor,
      color: '#1D232A',
    },
    Header: {
      color: '#1D232A',
    },
  };
};

const brandedColors = {
  Chat: {
    MessageListItem: {
      FromOthers: brandMessageBubbleColors(brandColor2),
      FromMe: brandMessageBubbleColors(brandColor1),
    },
    MessageInput: {
      Container: {
        background: 'transparent',
        color: '#1D232A',
        '::placeholder': {
          color: '#1D232A',
        },
      },
      Button: {
        background: '#42EC9A',
        color: '#1D232A',
      },
    },
    MessageCanvasTray: {
      Container: {
        background: personalizedColors.darkBlueBackground,
        color: personalizedColors.whiteText,
      },
    },
    MainContainer: {
      Container: {
        borderRadius: '12px',
        width: '320px',
      },
    },
  },
  MainHeader: {
    Container: {
      background: personalizedColors.darkBlueBackground,
      color: personalizedColors.whiteText,
    },
  },
  EntryPoint: {
    Container: {
      background: personalizedColors.entryPointBackground,
      color: personalizedColors.whiteText,
    },
  },
  DynamicForm: {
    Container: {
      borderRadius: '12px',
      width: '320px',
    },
  },
  PreEngagementCanvas: {
    Container: {
      background: '#fff',
    },
    Form: {
      SubmitButton: {
        background: '#42EC9A',
        width: '100%',
        color: '#1D232A',
        borderRadius: '32px',
        padding: '12px 16px',
        textTransform: 'capitalize',
        fontWeight: '450',
        fontSize: '16px',
        fontFamily: 'Circular Std',
      },
    },
  },
};

function loadJS(url, implementationCode, location, id = '') {
  const scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.id = id;

  scriptTag.onload = implementationCode;
  location.appendChild(scriptTag);
}

function loadTwilioChat({ loadRcFlow }) {
  return () => {
    const appConfig = {
      fileAttachment: {
        enabled: true,
      },
      markdownSupport: {
        enabled: false,
      },
      accountSid: process.env.TWILIO_ACCOUNT_SID,
      flexFlowSid: loadRcFlow
        ? process.env.RC_FLEX_FLOW_SID
        : process.env.TWILIO_FLEX_FLOW_SID,
      componentProps: {
        MainHeader: {
          titleText: 'Stone',
          imageUrl:
            'https://res.cloudinary.com/dunz5zfpt/image/upload/v1605130796/widget/potencia.svg',
        },
        Chat: {
          MessageListItem: {
            FromMe: {
              borderRadius: '8px 0px 8px 8px',
            },
            FromOthers: {
              borderRadius: '0px 8px 8px 8px',
            },
          },
        },
        MessageCanvasTray: {
          onButtonClick: () => {
            Twilio.FlexWebChat.Actions.invokeAction('RestartEngagement');
            document.getElementById('twilio-customer-frame').hidden = true;
            dispatchEvent(new CustomEvent('StoneWidget.openWidget'));
          },
        },
        MessagingCanvas: {
          showTypingIndicator: true,
          showReadStatus: true,
        },
        PreEngagementCanvas: {
          background: '#ff6384',
        },
      },
      colorTheme: {
        overrides: brandedColors,
      },
      context: {
        urlWithParams: buildUrlUtm(`${window.location.origin}/`),
      },
      startEngagementOnInit: false,
      preEngagementConfig: {
        description: 'Preencha seus dados abaixo para começar seu atendimento',
        fields: [
          {
            label: 'Nome Completo',
            type: 'InputItem',
            attributes: {
              name: 'friendlyName',
              type: 'text',
              required: true,
              placeholder: 'Insira seu nome completo',
            },
          },
          {
            label: 'Celular',
            type: 'InputItem',
            attributes: {
              name: 'friendlyPhone',
              type: 'phone',
              required: true,
              placeholder: 'Insira seu número de celular',
            },
          },
          {
            label: 'E-mail',
            type: 'InputItem',
            attributes: {
              name: 'friendlyEmail',
              type: 'email',
              required: true,
            },
          },
          {
            label: 'Qual o canal que prefere ser contado?',
            type: 'SelectItem',
            attributes: {
              name: 'contactChannel',
              required: true,
              readOnly: false,
              placeholder: 'Escolha o canal de sua preferência',
            },
            options: [
              {
                value: 'Chat',
                label: 'chat',
                selected: false,
              },
              {
                value: 'WhatsApp',
                label: 'whatsapp',
                selected: false,
              },
            ],
          },
        ],
        submitLabel: 'Continuar',
      },
    };

    Twilio.FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;

    Twilio.FlexWebChat.createWebChat(appConfig).then(webchat => {
      const { manager } = webchat;

      manager.strings = {
        EntryPointTagline: 'Fale Conosco',
        MessageCanvasTrayContent: `
          <h6> Obrigado por conversar conosco! </h6>
          <p> Se você tiver mais alguma dúvida, entre em contato novamente. </p> `,
        MessageCanvasTrayButton: 'INICIAR NOVO CHAT',
        InvalidPreEngagementMessage:
          'Os formulários de pré-engajamento não foram definidos e são necessários para iniciar o bate-papo na Web.' +
          'Por favor, configure-os agora na instalação.',
        InvalidPreEngagementButton: 'Saiba mais',
        PredefinedChatMessageAuthorName: 'Stone',
        PredefinedChatMessageBody: 'Olá! Como podemos ajudá-lo hoje?',
        InputPlaceHolder: 'Digite a sua mensagem',
        TypingIndicator: '{{name}} está digitando ...',
        Read: 'Lido',
        MessageSendingDisabled: 'O envio de mensagens foi desativado',
        Today: 'HOJE',
        Yesterday: 'Ontem',
        WelcomeMessage: 'Bem vindo ao atendimento Stone!',
        Save: 'SALVAR',
        Reset: 'RESET',
        MessageCharacterCountStatus: '{{currentCharCount}} / {{maxCharCount}}',
        SendMessageTooltip: 'Enviar mensagem',
        FieldValidationRequiredField: 'Campo obrigatório',
        FieldValidationInvalidEmail: 'Forneça um endereço de email válido',
      };

      const audio = new Audio(
        'https://res.cloudinary.com/dunz5zfpt/video/upload/v1627592150/audios/site-notification.mp3',
      );

      Twilio.FlexWebChat.Actions.on('beforeStartEngagement', ({ formData }) => {
        if (formData.contactChannel.toLowerCase() !== 'whatsapp') {
          document.getElementById('twilio-customer-frame').hidden = false;
          Twilio.FlexWebChat.Actions.invokeAction('ToggleChatVisibility');
          dispatchEvent(new CustomEvent('StoneWidget.closeWidget'));
        }
      });

      Twilio.FlexWebChat.Actions.on('afterStartEngagement', ({ formData }) => {
        if (formData.contactChannel.toLowerCase() === 'whatsapp') {
          const { channelSid } = manager.store.getState().flex.session;

          manager.chatClient.getChannelBySid(channelSid).then(channel => {
            channel.sendMessage('Quero falar via Whatsapp').then(() => {
              Twilio.FlexWebChat.Actions.invokeAction('RestartEngagement');
            });
          });

          document.getElementById('twilio-customer-frame').hidden = true;
        } else {
          const { channelSid } = manager.store.getState().flex.session;
          manager.chatClient.getChannelBySid(channelSid).then(channel => {
            channel.sendMessage(
              loadRcFlow
                ? 'Oi! Sou cliente Stone e gostaria de falar com vocês.'
                : 'Olá, gostaria de saber como a Stone pode ajudar meu negócio. Obrigado(a)',
            );
          });
        }
      });

      Twilio.FlexWebChat.Notifications.addListener(
        'beforeAddNotification',
        payload => {
          if (payload.id === 'NewChatMessage') {
            const [input] = document.getElementsByClassName('css-143wq4d');
            const { title } = document;

            const intervalId = setInterval(() => {
              const { activeElement } = document;
              if (activeElement !== input) {
                document.title = '1 Nova Mensagem...';
              } else {
                clearInterval(intervalId);
                document.title = title;
              }
            }, 500);

            audio.play();
          }
        },
      );

      webchat.init();

      const { engagementStage } = manager.store.getState().flex.session;

      if (engagementStage === 'CF_PRE_ENGAGEMENT') {
        document.getElementById('twilio-customer-frame').hidden = true;
        // TODO: Não tem ouvintes para esse dispatch. Foi comentado para resolver um problema
        // de chamadas excessivas para getBusinessHoursChannels.
        // É importante fazer uma investigação desse pedaço de código em outro momento.
        // dispatchEvent(new CustomEvent('StoneWidget.setWidgetVisible'));
      }
    });
  };
}

export const loadTwilio = ({ loadRcFlow = false } = {}) => {
  loadJS(
    'https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.1/twilio-flex-webchat.min.js',
    loadTwilioChat({ loadRcFlow }),
    document.body,
  );
};
