/* eslint-disable @typescript-eslint/no-explicit-any */

interface Data {
  coupon?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  offer_id?: string;
  product_name?: string;
}

interface BaseInterface {
  setNext(next: BaseInterface): BaseInterface;
  handle(data: Data): boolean;
  valueToBeValidated?: string;
}

abstract class BaseAbstractClass implements BaseInterface {
  private nextHandler?: BaseInterface;
  public valueToBeValidated?: string;

  constructor(valueToBeValidated?: string) {
    this.valueToBeValidated = valueToBeValidated;
  }

  public setNext(next: BaseInterface): BaseInterface {
    this.nextHandler = next;

    return next;
  }

  public handle(data: Data) {
    if (this.nextHandler) {
      return this.nextHandler.handle(data);
    }

    return true;
  }
}

export class HasCoupon extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data?.coupon) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasUtmSource extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data.utm_source) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasDefinedUtmSource extends BaseAbstractClass {
  constructor(valueToBeValidated: string) {
    super(valueToBeValidated);
  }

  public handle(data: Data) {
    if (data?.utm_source !== this.valueToBeValidated) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasUtmMedium extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data.utm_medium) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasDefinedUtmMedium extends BaseAbstractClass {
  constructor(valueToBeValidated: string) {
    super(valueToBeValidated);
  }

  public handle(data: Data) {
    if (data?.utm_medium !== this.valueToBeValidated) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasUtmCampaign extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data.utm_campaign) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasDefinedUtmCampaign extends BaseAbstractClass {
  constructor(valueToBeValidated: string) {
    super(valueToBeValidated);
  }

  public handle(data: Data) {
    if (data?.utm_campaign !== this.valueToBeValidated) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasUtmTerm extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data.utm_term) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasOfferId extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data.offer_id) {
      return false;
    }

    return super.handle(data);
  }
}

export class HasProductName extends BaseAbstractClass {
  public handle(data: Data) {
    if (!data.product_name) {
      return false;
    }

    return super.handle(data);
  }
}
