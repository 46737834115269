'use client';

import { createContext, useCallback, useContext, useState } from 'react';

import QRCode from '@/components/v3/QRCode';
import LINKS from '@/constants/links';

type QRCodeDrawerContextType = {
  toggleDrawer: () => void;
  setQrCodeLink: (link: string) => void;
  qrCodeLink?: string;
};

const QRCodeDrawerContextDefaultValues: QRCodeDrawerContextType = {
  toggleDrawer: () => undefined,
  setQrCodeLink: () => undefined,
};

const QRCodeDrawerContext = createContext<QRCodeDrawerContextType>(
  QRCodeDrawerContextDefaultValues,
);

export const useQRCodeDrawer = (): QRCodeDrawerContextType => {
  return useContext(QRCodeDrawerContext);
};

export const QRCodeDrawerProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [qrCodeLink, setQrCodeLink] = useState(
    LINKS.APP_LINK_APPSFLYER_QR_CODE_LINK_PAGAMENTO,
  );

  const toggleDrawer = useCallback(() => {
    setIsVisible(value => !value);
  }, []);

  return (
    <QRCodeDrawerContext.Provider
      value={{
        toggleDrawer,
        setQrCodeLink,
      }}
    >
      <>
        <QRCode
          isShowing={isVisible}
          onToggle={toggleDrawer}
          qrCodeLink={qrCodeLink}
        />
        {children}
      </>
    </QRCodeDrawerContext.Provider>
  );
};
