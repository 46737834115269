import { useReCaptcha } from 'next-recaptcha-v3';
import { useCallback, useEffect, useRef } from 'react';
import PackageJson from '../../package.json';

export const useHttpClient = (
  httpClient: (endpoint: string, options?: RequestInit) => Promise<Response>,
) => {
  const recaptcha = useReCaptcha();
  const token = useRef('');

  const acquireToken = useCallback(async (): Promise<string> => {
    try {
      if (recaptcha.loaded && (await recaptcha.executeRecaptcha?.('submit'))) {
        return recaptcha.executeRecaptcha('submit');
      }
      return '';
    } catch (e) {
      console.error(e);
      return '';
    }
  }, [recaptcha]);

  useEffect(() => {
    const setToken = async () => {
      token.current = await acquireToken();
    };
    setToken();
  }, [acquireToken]);

  const fetchWithRecaptcha = useCallback(
    async (endpoint: string, options: RequestInit = {}): Promise<Response> => {
      let endpointWithQuery = endpoint;
      const shouldAddRecaptcha = shouldAuthenticateWithRecaptcha(endpoint);
      if (shouldAddRecaptcha) {
        const recaptchaToken = token.current || (await acquireToken());

        endpointWithQuery += `?recaptcha=${recaptchaToken}`;
      }

      const response = await httpClient(endpointWithQuery, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response;
    },
    [httpClient, acquireToken],
  );

  return { fetch: fetchWithRecaptcha };
};

function shouldAuthenticateWithRecaptcha(pathname: string) {
  return ['/preleads', '/onboarding', '/inbounds'].some(x =>
    pathname.startsWith(x),
  );
}

export const BFF_HEADERS_CONFIG = {
  'stn-version': PackageJson.version,
  'stn-platform': 'Web',
};

export const createBFFFetch = () => {
  return async (endpoint: string, options: RequestInit = {}) => {
    const url = `${process.env.AUTOCRED_BFF_URL}${endpoint}`;
    options.headers = {
      ...options.headers,
      ...BFF_HEADERS_CONFIG,
    };

    return fetch(url, options);
  };
};

export const bffHttp = createBFFFetch();
