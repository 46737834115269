import { atom, selector } from 'recoil';

import { RcChannelResponse } from '@/services/getRcDepartments';

export const widgetIsOpenState = atom({
  key: 'widgetIsOpenState',
  default: false,
});

export const chatIsOpenState = atom({
  key: 'chatIsOpenState',
  default: true,
});

export const widgetActiveChatIsOpenState = atom({
  key: 'widgetActiveChatIsOpenState',
  default: false,
});

export const widgetIsHiddenState = atom({
  key: 'widgetIsHiddenState',
  default: true,
});

export const twilioIsOpenState = atom({
  key: 'twilioIsOpen',
  default: false,
});

export const hasChatChannelAvaibleState = atom({
  key: 'hasChatChannelAvaibleState',
  default: false,
});

export const isLoadingChatChannelAvaibleState = atom({
  key: 'isLoadingChatChannelAvaibleState',
  default: false,
});

export const isClientState = atom({
  key: 'isClientState',
  default: false,
});

export const cardStepState = atom({
  key: 'cardStepState',
  default: 0,
});

export const rcDepartamentsState = atom<RcChannelResponse[]>({
  key: 'rcDepartamentsState',
  default: [],
});

export const userDataState = atom({
  key: 'userDataState',
  default: {
    name: '',
    email: '',
    phone: '',
  },
});

export const togglesState = atom({
  key: 'toggles',
  default: {
    widgetRcUseTwilio: false,
  },
});

export const toggleRcUseTwilioState = selector({
  key: 'toggleRcUseTwilioState',
  get: ({ get }) => {
    const toggles = get(togglesState);

    return toggles.widgetRcUseTwilio;
  },
});

export const finishMessagesState = atom({
  key: 'finishMessagesState',
  default: false,
});
