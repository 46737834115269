import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '662c2999-bb6c-4de9-a60c-28544ae61d9e',
  clientToken: 'pubfeae2d51d5f54b82f7dcecb851175c3b',
  site: 'datadoghq.com',
  service: 'stone-site-front-end',
  env: process.env.DD_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 2,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  version: process.env.NEXT_PUBLIC_NODE_VERSION,
});

datadogRum.setGlobalContext({
  owner: 'tribo-web-stone',
  project: 'stone-site-front-end',
  'cost-center': '860290032',
  support: 'tribo-web-stone',
});

export default function DatadogInit() {
  return null;
}
