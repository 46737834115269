import { listedIconsList } from '@/assets/icons/index';

import Icon, { Icons, IconsNames } from '../v3/Icon';

export const ListIcons = () => {
  return (
    <>
      <div className="grid overflow-y-scroll grid-cols-5 gap-36 h-[50rem]">
        {Object.keys(Icons)
          .sort()
          .map(key => {
            return (
              <div className="flex flex-col justify-center items-center w-full">
                <Icon
                  name={key as IconsNames}
                  className="w-36 h-36 text-stone-500 fill-current"
                />
                <p>{key}</p>
              </div>
            );
          })}
        {listedIconsList.map(Item => {
          return (
            <div className="flex flex-col justify-center items-center w-full">
              <Item className="w-36 h-36" />
              <p>
                {Item.name?.includes('Svg')
                  ? Item.name.replace('Svg', '')
                  : Item.name}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
