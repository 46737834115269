/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */

'use client';

import classNames from 'classnames';
import { m, Variants } from 'framer-motion';
import React from 'react';

import Icon from '@/components/v3/Icon';

import ClientOnlyPortal from '../ClientOnlyPortal';
import styles from './Drawer.module.css';

const containerVariants: Variants = {
  open: {
    width: '100%',
    transition: {
      duration: 0,
      delayChildren: 0.3,
    },
  },
  close: {
    width: 0,
    transition: {
      duration: 0,
      when: 'afterChildren',
    },
  },
};

const drawerVariants: Variants = {
  open: {
    transition: {
      bounce: 0,
    },
    width: 'auto',
  },
  close: {
    width: 0,
  },
};

type DrawerProps = {
  isOpen: boolean;
  onClose?: () => void;
  closeOnClickOut?: boolean;
  children: React.ReactNode;
  className?: string;
};

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  closeOnClickOut,
  children,
}) => {
  return (
    <ClientOnlyPortal selector="#drawer">
      <m.ul
        variants={containerVariants}
        initial="close"
        animate={isOpen ? 'open' : 'close'}
        className={classNames(styles.container, {
          [styles.isOpen]: isOpen,
        })}
      >
        <li>
          <div
            role="button"
            onClick={closeOnClickOut ? onClose : () => undefined}
            className={styles.background}
          />
        </li>
        <m.li variants={drawerVariants} className={styles.drawer}>
          <div className="flex overflow-x-auto flex-col items-center p-24 xs:py-32 xs:px-56 w-full xs:w-auto xxs:min-w-[348px] xs:min-w-[376px]">
            {onClose && (
              <button
                type="button"
                onClick={onClose}
                className="p-8 mb-24 sm:mb-40 ml-auto w-40 h-40 bg-display-100 rounded-full"
                aria-label="close"
              >
                <Icon name="close" className="text-display-600 fill-current" />
              </button>
            )}
            {children}
          </div>
        </m.li>
      </m.ul>
    </ClientOnlyPortal>
  );
};

export default Drawer;
