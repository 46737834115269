import { fetchWithTimeout } from '@/services/inboundApi';
import { getUrlParams } from '@/utils/urlParams';

export type createLeadParams = {
  tdc?: string;
  tpv: string;
  name: string;
  email: string;
  phone: string;
  source: string;
  channel: string;
  gaClientId: string;
  gaTrackingId: string;
};

export const formatLeadData = (data: createLeadParams) => {
  return {
    tpv: data.tpv,
    tdc: data.tdc,
    email: data.email,
    source: data.source,
    channel: data.channel,
    gaClientId: data.gaClientId,
    gaTrackingId: data.gaTrackingId,
    otherFields: getUrlParams(),
    phone: data.phone.replace(/\D/g, ''),
    lastName: data.name.substr(data.name.indexOf(' ') + 1),
    firstName: data.name.substr(0, data.name.indexOf(' ')),
  };
};

export const createLead = async (data: createLeadParams) => {
  const formattedData = formatLeadData(data);

  const response = await fetchWithTimeout('leadCreateExternal', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formattedData),
  });

  if (!response.ok) {
    throw new Error('Failed to create lead');
  }

  return response.json(); // Retorna a resposta JSON se necessário
};
