import React from 'react';

import { InboundProvider } from './inbound';
import { InboundDrawerProvider } from './inboundDrawer';
import { QRCodeDrawerProvider } from './qrcodeDrawer';

const ContextsProvider: React.FC<{
  children?: React.ReactNode;
  pathname: string;
}> = ({ children, pathname }) => {
  return (
    <InboundProvider>
      <InboundDrawerProvider pathname={pathname}>
        <QRCodeDrawerProvider>{children}</QRCodeDrawerProvider>
      </InboundDrawerProvider>
    </InboundProvider>
  );
};

export default ContextsProvider;
