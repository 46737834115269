/* eslint-disable @typescript-eslint/no-unused-vars */
import { TChannelForm, TInboundAttendanceStatus } from '@/types/inbound';

type TAttendanceStatusTextItem = {
  [key in TInboundAttendanceStatus]: string;
};

export const attendanceStatusTitle: TAttendanceStatusTextItem = {
  available: 'Disponível',
  scheduled: 'Agendado',
};

export const attendanceStatusFormTitle: {
  [key in TInboundAttendanceStatus]: string;
} = {
  available: 'Preencha seus dados',
  scheduled: 'Preencha seus dados',
};

export const attendanceStatusFormTitleTon: {
  [key in TInboundAttendanceStatus]: string;
} = {
  available: 'Preencha seus dados e peça já:',
  scheduled: 'Preencha seus dados e peça já:',
};

export const defaultChannelsOrder: TChannelForm[] = [
  'chat',
  'whatsapp',
  'whatsapp-out-business-hours',
  'phone-c2c',
];

export const tpvOptions = [
  {
    label: 'Até R$ 10.000',
    value: 'Até R$ 10.000,00',
  },
  {
    label: 'Entre R$ 10.000 e R$ 15.000',
    value: 'Entre R$ 10.000 e R$ 15.000',
  },
  {
    label: 'Entre R$ 15.000 e R$ 30.000',
    value: 'Entre R$ 15.000 e R$ 30.000',
  },
  {
    label: 'Entre R$ 30.000 e R$ 200.000',
    value: 'Entre R$ 30.000 e R$ 200.000',
  },
  {
    label: 'Acima de R$ 200.000',
    value: 'Acima de R$ 200.000',
  },
];

/**
 * TODO: @Vinicius Passos falou que é possível buscar os dados abaixo de uma API
 * Acredito que seria um esforço válido alinhar junto ao time tech de RC
 * */
export const initialRcDepartments = [
  {
    id: 1,
    label: 'Maquininhas e Aplicativos',
    value: 'Front',
  },
  {
    id: 2,
    label: 'Link de Pagamento',
    value: 'Front',
  },
  {
    id: 3,
    label: 'Vendas por e-commerce',
    value: 'Cyber',
  },
  {
    id: 4,
    label: 'Outros assuntos',
    value: 'Front',
  },
];
