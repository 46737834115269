import dynamic from 'next/dynamic';

const SocialsIcons2x = {
  'logo-raio-x-2x': dynamic(() => import('./LogoRaioX')),
  'user-customer-support-2x': dynamic(() => import('./UserCustomerSupport')),
  'message-2x': dynamic(() => import('./Message')),
  'user-2x': dynamic(() => import('./User')),
  'user-users-2x': dynamic(() => import('./UserUsers')),
  'logo-stone-2x': dynamic(() => import('./LogoStone')),
  'logo-whatsapp-2x': dynamic(() => import('./LogoWhatsapp')),
  'email-2x': dynamic(() => import('./Mail')),
  'user-check-2x': dynamic(() => import('./UserCheck')),
};

export default SocialsIcons2x;
