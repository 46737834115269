/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { getStonePlans } from '@/services/getStonePlans';
import useStore, { IAutoCredState, InitialMachinePrices } from '@/store';
import getUtmUrl from '@/utils/getUtmUrl';

import { rexChainSecondFlowValidator } from '../helpers/mgm/rexChainSecondFlowValidator';

interface IUtmRexFirstFlow {
  coupon: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
}

export interface IUtmRexSecondFlow extends IUtmRexFirstFlow {
  offer_id: string;
  product_name: string;
}

interface IMachinePrices {
  standard_machine_price: number;
  standard_machine_installments?: {
    quantity?: number;
    value?: number;
  };
  smart_machine_price: number;
  smart_machine_installments?: {
    quantity?: number;
    value?: number;
  };
}

async function handleRexSecondFlow(store: IAutoCredState) {
  const { offer_id, product_name, ...utms } = getUtmUrl() as IUtmRexSecondFlow;

  // Valida a existência do plano pelo offer_id
  const stonePlans = await getStonePlans(true);
  const stonePlanFound = stonePlans.find(
    (plan: any) => plan.offer_id === offer_id,
  );

  if (!stonePlanFound) return false;

  // Define a maquininha escolhida
  switch (product_name) {
    case 'pos-wifi-acquisition':
      store.updateStandardMachineQuantity(1);
      store.updateSmartMachineQuantity(0);
      break;

    case 'pos-smart-acquisition':
      store.updateStandardMachineQuantity(0);
      store.updateSmartMachineQuantity(1);
      break;
  }

  const { coupon, utm_source, utm_medium, utm_campaign, utm_term } = utms;

  // Salva o cupom
  store.updateCoupon(coupon);

  // Salva os parâmetros da URL
  store.updateWithUtmParams({
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
  });

  const { plan_name } = stonePlanFound;

  // Filtra os dados comerciais dos produtos vinculado ao plano escolhido (Maquininhas Padrão e Smart)
  const products = stonePlanFound.products.filter((plan: any) =>
    ['pos-wifi-acquisition', 'pos-smart-acquisition'].includes(plan.name),
  );

  // Monta o array de preços das maquininhas para salvar na Store
  const machinePrices = products.reduce((acc: any, prod: any) => {
    if (prod.name === 'pos-wifi-acquisition') {
      acc = {
        ...acc,
        standard_machine_price: prod.price,
        standard_machine_installments: prod.installment,
      };
    }

    if (prod.name === 'pos-smart-acquisition') {
      acc = {
        ...acc,
        smart_machine_price: prod.price,
        smart_machine_installments: prod.installment,
      };
    }

    return acc;
  }, {} as IMachinePrices);

  // Inicia com o plano e os preços das maquininhas
  store.updateInitialMachinesPricesAndMachineName({
    offer_id,
    plan_name,
    ...machinePrices,
  } as InitialMachinePrices);
}

export const useRex = () => {
  const store = useStore();

  useEffect(() => {
    const utmParams = getUtmUrl();
    const isItRexSecondFlow = rexChainSecondFlowValidator.handle(utmParams);

    if (isItRexSecondFlow) {
      handleRexSecondFlow(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
