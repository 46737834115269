import { CATEGORIES } from '@/constants/analytics';
import { TAllChannelForm, TChannel } from '@/types/inbound';
import { GAsendEvent } from '@/utils/Analytics';

import { fetchWithTimeout } from './inboundApi';

const fetchWithRetry = async (
  url: string,
  retries: number,
): Promise<Response> => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    if (retries > 0) {
      return fetchWithRetry(url, retries - 1);
    }

    throw error;
  }
};

export const getBusinessHoursChannels = async (
  retries = 0,
): Promise<TChannel[]> => {
  try {
    const response = await fetchWithRetry(
      `${process.env.ONE_API_HOST}/cache/business-hours`,
      4,
    );
    const data: TAllChannelForm[] = await response.json();

    const channels = data.find(({ team }) => team === 'Inbound')?.channels;

    GAsendEvent(
      CATEGORIES.SITE_STONE,
      'ERROR',
      `Error on getBusinessHours`,
      retries,
    );

    return (channels as TChannel[]) || ([] as unknown as TChannel);
  } catch (err) {
    if (retries <= 4) {
      return getBusinessHoursChannels(retries + 1);
    }

    GAsendEvent(
      CATEGORIES.SITE_STONE,
      'ERROR',
      `Fatal Error on getBusinessHours`,
    );
    return [];
  }
};

interface ChannelsResponse {
  channels: TChannel[];
}

export const getInboundChannels = async (): Promise<TChannel[]> => {
  try {
    const response = await fetchWithTimeout(
      '/inbound',
      { method: 'GET' },
      5000,
    );

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data: ChannelsResponse = await response.json();

    return data.channels;
  } catch (err) {
    return ['phone'];
  }
};
