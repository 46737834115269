export const CATEGORIES = {
  SITE_STONE: 'Site Stone',
  INBOUND_LEAD: 'Inbound Lead',
  INBOUND_FORM: 'Inbound Form',
  RC_CONTACT: 'RC Contact',
  RC_FORM: 'RC Form',
};

export const ACTIONS = {
  ADQUIRA: 'Adquira',
  WIDGET: 'Widget',
  CLICK_CTA: 'Clique CTA',
  CLICK_LINK: 'Clique Link',
  CLICK_MENU: 'Clique Menu',
  CLICK_WIDGET: 'Clique Widget',
  CLICK_CTA_IDEAL_SOLUTION_STONE: 'Clique CTA Solução Ideal Stone',
  CLICK_CTA_IDEAL_SOLUTION_TON: 'Clique CTA Solução Ideal Ton',
};

export const CHANNEL_LABELS = {
  ACTIVE_CHAT: 'Chat Ativo',
  PHONE: 'Telefone',
  CHAT: 'Chat',
  WHASTSAPP: 'WhatsApp',
  WHASTSAPP_OUT_BUSINESS_HOURS: 'WhatsApp fora do horário',
  WE_CALL: 'A gente te liga',
};
