/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import '../styles/main.css';

import { domAnimation, LazyMotion } from 'framer-motion';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { RecoilRoot } from 'recoil';

import { WebVitals } from '@/app/components/base/WebVitals';
import { DevToolsPanel } from '@/components/devtools';
import { GTM_SETTINGS } from '@/constants/analyticsSettings';
import IS_SERVER from '@/constants/isServer';
import ContextsProvider from '@/contexts';
import { ExperimentProvider } from '@/contexts/amplitude';
import { FormInboundProvider } from '@/contexts/formInbound';
import { PlansProvider } from '@/contexts/plans';
import { ShowCalculatorProvider } from '@/contexts/showCalculator';
import { uuid } from '@/helpers/uuid';
import { sharonDisplay, sharonSans, sharonSerif } from '@/public/fonts/fonts';
import {
  ampli as amplitudeAnalytics,
  DefaultConfiguration,
  ensureGetUserId,
} from '@/utils/amplitude';
import { GAinitialize } from '@/utils/Analytics';
import buildUrlUtm from '@/utils/buildUrlUtm';
import DatadogInit from '@/utils/datadog-init';
import { isExternalUrl } from '@/utils/url';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';

const Widget = dynamic(() => import('@/components/Widget'), { ssr: false });

function clicked(event: MouseEvent) {
  const origin = (event.target as HTMLElement).closest(
    'a',
  ) as HTMLAnchorElement | null;
  if (origin) {
    if (isExternalUrl(origin.host)) {
      event.preventDefault();
      event.stopPropagation();

      if (origin.target) {
        return window.open(buildUrlUtm(origin.href), '_blank');
      }

      return (window.location = buildUrlUtm(origin.href) as (
        | string
        | Location
      ) &
        Location);
    }
  }
}

let experiment: ExperimentClient;

type CustomAppProps = AppProps & {
  pathname: string;
};

const MyApp = ({ Component, pageProps, pathname }: CustomAppProps) => {
  useEffect(() => {
    TagManager.initialize(GTM_SETTINGS);

    (() => GAinitialize())();

    const [, query] = window.location.search.split('?');

    let queryData: { key: string; value: string }[] | null = [];

    if (query) {
      const fields = query.split('&');
      for (let i = 0; i < fields.length; i += 1) {
        const [key, value] = fields[i].split('=');
        if (
          key.indexOf('utm_') === 0 ||
          key.indexOf('gclid') === 0 ||
          key.indexOf('fbclid') === 0 ||
          key.indexOf('defaultChannel') === 0 ||
          key.indexOf('af_') === 0 ||
          key.indexOf('c') === 0
        ) {
          queryData.push({ key, value });
        }
      }
    }

    if (queryData.length) {
      sessionStorage.setItem('queryData', JSON.stringify(queryData));
    }

    queryData = null;

    document.addEventListener('click', clicked);

    amplitudeAnalytics.load({
      client: {
        apiKey: String(process.env.AMPLITUDE_ANALYTICS_API_KEY),
        configuration: {
          ...DefaultConfiguration,
          logLevel: 3,
          userId: ensureGetUserId(),
        },
      },
    });
  }, []);

  const router = useRouter();
  const [, setHistory] = useState<string[]>([router.asPath]);

  useEffect(() => {
    if (router.pathname.includes('/adquira-ton')) {
      router.push('https://www.ton.com.br/adquira/');
    }
  }, [router, router.pathname]);

  useEffect(() => {
    if (IS_SERVER) return;

    if (!sessionStorage?.getItem('user-sync-id')) {
      sessionStorage?.setItem('user-sync-id', uuid());
    }

    const handleRouteChange = (url: string) => {
      setHistory((history: string[]) => {
        sessionStorage?.setItem(
          'amplitude-source',
          window.location.host + history[history.length - 1],
        );

        return [...history, url];
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  if (!IS_SERVER && !experiment) {
    experiment = Experiment.initializeWithAmplitudeAnalytics(
      String(process.env.AMPLITUDE_EXPERIMENTS_API_KEY),
      {},
    );
  }

  const devToolsPanelEnv = process.env.DEV_TOOLS === 'enabled';

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, minimum-scale=1, width=device-width, height=device-height"
        />
      </Head>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>

      <WebVitals />

      <DatadogInit />

      <RecoilRoot>
        <LazyMotion features={domAnimation}>
          <ExperimentProvider
            value={{
              experiments: experiment,
              analytics: amplitudeAnalytics,
            }}
          >
            <ContextsProvider pathname={pathname}>
              <ReCaptchaProvider
                useRecaptchaNet
                useEnterprise
                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY}
              >
                <PlansProvider>
                  <FormInboundProvider>
                    <ShowCalculatorProvider>
                      <div id="menuMobile" />
                      <main
                        className={`${sharonSans.variable} ${sharonDisplay.variable} ${sharonSerif.variable}`}
                      >
                        <Component {...pageProps} pathname={pathname} />
                      </main>
                      {router.pathname == '/modostone' ||
                      router.asPath.includes('mgm') ? (
                        ''
                      ) : (
                        <Widget twilioReqSource="stone_widget_active_chat" />
                      )}
                    </ShowCalculatorProvider>
                  </FormInboundProvider>
                </PlansProvider>
                {devToolsPanelEnv ? <DevToolsPanel /> : null}
              </ReCaptchaProvider>
            </ContextsProvider>
          </ExperimentProvider>
        </LazyMotion>
      </RecoilRoot>
    </>
  );
};

MyApp.getInitialProps = ({ ctx }: AppContext) => ({
  pathname: ctx?.req?.url || '',
});

export default MyApp;
