'use client';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useContext, useEffect, useState } from 'react';

import { If } from '@/components/UtilitiesComponents';
import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';
import { useInbound } from '@/contexts/inbound';
import {
  attendanceStatusFormTitle,
  attendanceStatusTitle,
} from '@/resources/inbound';
import { checkPathAccess } from '@/services/normalizedPath';

type InboundAttendanceStatusHandlerProps = {
  hideTitle?: boolean;
  children?: React.ReactNode;
};
const InboundAttendanceStatusHandler: React.FC<
  InboundAttendanceStatusHandlerProps
> = ({ children, hideTitle }) => {
  const { attendanceStatus, hasPhoneChannelAvailable } = useInbound();
  const isNotUnavailable =
    attendanceStatus === 'available' || attendanceStatus === 'scheduled';
  const { analytics } = useContext(AmplitudeContext);
  const listText = [
    {
      description: 'Entregas e trocas em 1 dia útil ',
      icon: 'flip-up2',
    },
    { description: 'Pix direto na Maquininha', icon: 'qr-code-pix' },
    { description: 'Recebimento em 1 dia útil', icon: 'round-dollar' },
    { description: 'Conta Stone grátis', icon: 'phone-mobile' },
  ];

  const pathname = usePathname() as string;
  const [acquire, setAcquire] = useState(false);

  const blackFridayOffer = process.env.IS_BLACK_FRIDAY;

  useEffect(() => {
    if (pathname) {
      setAcquire(checkPathAccess({ pathname, pathRestrictions: '/adquira' }));
    }
  }, [pathname]);

  return (
    <>
      {isNotUnavailable && (
        <div className="flex relative flex-col md:justify-center md:pt-0 w-full min-h-screen">
          <div className="flex md:hidden absolute inset-x-0 top-0 justify-center">
            <div className="flex justify-between items-center py-10 px-24 mb-24 w-full border-b border-display-200 border-solid">
              <Link href="/" aria-label="Link para página inicial">
                <Icon
                  name="logo/stone"
                  className="w-[92px] h-[24px] fill-current"
                />
              </Link>
              <div className="flex flex-col">
                {hasPhoneChannelAvailable ? (
                  <div>
                    <a
                      href="tel:30049681"
                      className="py-12 px-16 btn btn-primary btn-regular"
                      onClick={() => {
                        analytics?.track({
                          event_type: 'contact stone',
                          event_properties: {
                            cta_reference: 'Adquira pelo telefone 3004 9681',
                            name: 'contact stone',
                            description:
                              'Evento disparado no clique sobre o telefone de contato Stone',
                          },
                        });
                      }}
                    >
                      <p className="flex text-white paragraph-16">
                        <Icon
                          name="phone"
                          className="mr-8 text-white fill-current"
                        />
                        Ligue
                      </p>
                    </a>
                  </div>
                ) : (
                  <>
                    <p className="paragraph-14">Atendimento</p>
                    <p className="font-bold text-right paragraph-16">
                      {attendanceStatusTitle[attendanceStatus]}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="md:hidden">
            <div className="flex relative flex-col justify-center items-center md:p-0 mt-[65px] md:mt-0 w-full bg-display-200">
              <If condition={acquire && Boolean(blackFridayOffer)}>
                <div className="flex flex-col gap-8 justify-center items-center p-16 w-full bg-[#00231B]">
                  <div className="relative min-w-[328px]">
                    <Image
                      width={328}
                      height={126}
                      layout="responsive"
                      src="stone-cms/prod/banner_mobile_007d6b7912.png"
                      className="object-contain"
                      alt="Produtos Stone - maquininha, cartão de crédito para empresas e pessoal e aplicativo com controle de vendas"
                    />
                  </div>
                  <p className="text-[12px] leading-2 text-white">
                    Confira as condições com nossos especialistas
                  </p>
                </div>
              </If>

              <div className="py-16 px-24">
                <div>
                  <h4 className="font-display text-2xl font-bold">
                    Fale com o nosso time
                  </h4>
                  <p>Conheça esses e outros os benefícios de ser Stone</p>
                  <div className="flex flex-col gap-8 mt-16">
                    {listText.map(({ description }) => (
                      <div className="flex gap-10">
                        <Icon
                          name="check"
                          className="w-24 h-24 text-stone-600 fill-current"
                        />
                        <p>{description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-display-200">
              <div className="flex flex-col gap-16 justify-center p-24 md:p-0 md:mt-0 w-full h-full bg-white rounded-t-2xl">
                <h6 className="font-display font-bold">
                  Informe abaixo os seus dados
                </h6>
                {children}
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-col gap-16 justify-center p-24 md:p-0 mt-[65px] md:mt-0 w-full h-full">
            {!hideTitle && (
              <div>
                <h1 className="font-display text-xl md:text-2xl font-bold leading-5 md:leading-6">
                  {attendanceStatusFormTitle[attendanceStatus]}
                </h1>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
};
export default InboundAttendanceStatusHandler;
