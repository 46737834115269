export async function fetchWithTimeout(
  endpoint: string,
  options: RequestInit = {},
  timeout = 5000,
): Promise<Response> {
  const controller = new AbortController();
  const { signal } = controller;
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_INBOUND_API_HOST}${endpoint}`,
      {
        ...options,
        signal,
      },
    );
    clearTimeout(id);
    return response;
  } catch (error) {
    clearTimeout(id);
    throw error;
  }
}
