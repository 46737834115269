import dynamic from 'next/dynamic';

const EmotionsHealthIcons2x = {
  'hand-heart-2x': dynamic(() => import('./HandHeart')),
  'hand-shake-2x': dynamic(() => import('./HandShake')),
  'loyalty-heart-2x': dynamic(() => import('./LoyaltyHeart')),
  'hospital-2x': dynamic(() => import('./Hospital')),
  'face-happy-2x': dynamic(() => import('./FaceHappy')),
} as const;

export default EmotionsHealthIcons2x;
