'use client';
import { IAutoCredState } from '@/store';

export const validateIsMGM = (data: IAutoCredState) => {
  const { utm_source, utm_medium, utm_campaign } = data;

  const hasMGMProps =
    utm_source === 'mgm' && utm_medium === 'mgm' && utm_campaign === 'stone';

  return hasMGMProps;
};

export const validateIsREX = (data: IAutoCredState) => {
  const { utm_source, utm_medium, utm_campaign } = data;

  const hasREXProps =
    utm_source === 'rex' && utm_medium === 'rex' && utm_campaign === 'ton';

  return hasREXProps;
};
