import dynamic from 'next/dynamic';

const ArrowIcons1x = {
  'arrow-right': dynamic(() => import('./ArrowRight')),
  'arrow-up': dynamic(() => import('./ArrowUp')),
  'arrow-up-right': dynamic(() => import('./ArrowUpRight')),
  'chevron-down': dynamic(() => import('./ChevronDown')),
  'chevron-up': dynamic(() => import('./ChevronUp')),
  'chevron-Right': dynamic(() => import('./ChevronRight')),
  'arrow-swap-horizontal': dynamic(() => import('./ArrowSwapHorizontal')),
  'arrow-down': dynamic(() => import('./ArrowDown')),
} as const;

export default ArrowIcons1x;
