/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { fetcher } from '@/utils/fetcher';
import translateTpvOptions from '@/utils/translateTpvOptions';

interface InboundLeadBase {
  name: string;
  phone: string;
  email: string;
  document: string;
}
interface FormatInboundLeadData extends InboundLeadBase {
  tpv: string;
}
export interface SaveInboundLead extends InboundLeadBase {
  tpv: number;
}

export function formatInboundLeadData(
  data: FormatInboundLeadData,
): SaveInboundLead {
  const formattedPhone = data.phone.replace(/\D/g, '');

  return { ...data, phone: formattedPhone, tpv: translateTpvOptions(data.tpv) };
}

export async function saveInboundLeads(data: FormatInboundLeadData) {
  const formattedData: SaveInboundLead = formatInboundLeadData(data);

  return fetcher(process.env.SAVE_INBOUND_LEADS_URL!, 'POST', formattedData);
}
