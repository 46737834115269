import dynamic from 'next/dynamic';

const SocialsIcons1x = {
  message: dynamic(() => import('./Message')),
  'people-question': dynamic(() => import('./PeopleQuestion')),
  'user-check': dynamic(() => import('./UserCheck')),
  mail: dynamic(() => import('./Mail')),
  'user-customer-support': dynamic(() => import('./UserCustomerSupport')),
  'logo-stone': dynamic(() => import('./LogoStone')),
  'logo-whatsapp': dynamic(() => import('./LogoWhatsapp')),
  'user-users': dynamic(() => import('./UserUsers')),
  'logo-raio-x': dynamic(() => import('./LogoRaioX')),
  email: dynamic(() => import('./Mail')),
};

export default SocialsIcons1x;
