import dynamic from 'next/dynamic';

const SpritedIcons = {
  'coin-stack': dynamic(() => import('./CoinStack')),
  'pos-nfc': dynamic(() => import('./PosNfc')),
  'qr-code-square': dynamic(() => import('./QrCodeSquare')),
  softwares: dynamic(() => import('./Softwares')),
  apps: dynamic(() => import('./Apps')),
};

export default SpritedIcons;
