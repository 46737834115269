'use client';

import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import Drawer from '@/components/v3/Drawer';
import { useInbound } from '@/contexts/inbound';
import { useInboundDrawer } from '@/contexts/inboundDrawer';
import { TChannelForm } from '@/types/inbound';

import { handleInboundFormSubmit } from '../../services';
import InboundAttendanceStatusHandler from '../InboundAttendanceStatusHandler';
import InboundForm, { TInboundFormData } from '../InboundFormV2';
import FinishMessages from '../InboundFormV2/components/FinishMessages';

type Props = {
  pathname: string;
};

const InboundDrawer: React.FC<Props> = ({ pathname }: Props) => {
  const router = useRouter();
  const [showSuccessMessage, setShowSuccessMessage] =
    useState<TChannelForm | null>(null);
  const { isVisible, toggleDrawer } = useInboundDrawer();
  const { channels } = useInbound();

  const handleSubmit = useCallback(
    async (data: TInboundFormData) => {
      await handleInboundFormSubmit(data);

      if (data.channel === 'chat') {
        toggleDrawer();
      } else {
        setShowSuccessMessage(data.channel);
      }
    },
    [toggleDrawer],
  );

  useEffect(() => {
    if (!isVisible && showSuccessMessage) {
      setShowSuccessMessage(null);
    }
  }, [isVisible, showSuccessMessage]);

  useEffect(() => {
    if (isVisible) {
      const twilioWidgetIsVisible = !!window.document.getElementsByClassName(
        'Twilio-MainContainer',
      ).length;

      if (window.Twilio && twilioWidgetIsVisible) {
        window.Twilio.FlexWebChat.Actions.invokeAction('MinimizeChat');
      }
    }
  }, [isVisible]);

  return router.pathname !== '/adquira' ? (
    <Drawer isOpen={isVisible} onClose={toggleDrawer} closeOnClickOut>
      <div className="my-auto w-full xs:min-w-[312px] max-w-[312px] max-h-full">
        {showSuccessMessage ? (
          <div className="flex items-center h-full">
            <FinishMessages
              channel={showSuccessMessage}
              onClose={toggleDrawer}
              hideCloseButton
            />
          </div>
        ) : (
          <InboundAttendanceStatusHandler>
            <InboundForm
              showFinishModalOnlyAtChat
              onFinishModalJustClose
              channels={channels}
              onSubmit={handleSubmit}
              pathname={pathname}
            />
          </InboundAttendanceStatusHandler>
        )}
      </div>
    </Drawer>
  ) : (
    <div />
  );
};

export default InboundDrawer;
