import {
  HasCoupon,
  HasDefinedUtmCampaign,
  HasDefinedUtmMedium,
  HasDefinedUtmSource,
  HasOfferId,
  HasProductName,
  HasUtmCampaign,
  HasUtmMedium,
  HasUtmSource,
  HasUtmTerm,
} from './chain-validators';

const hasCoupon = new HasCoupon();
const hasUtmSource = new HasUtmSource();
const hasRexUtmSource = new HasDefinedUtmSource('rex');
const hasUtmMedium = new HasUtmMedium();
const hasRexUtmMedium = new HasDefinedUtmMedium('rex');
const hasUtmCampaign = new HasUtmCampaign();
const hasRexUtmCampaign = new HasDefinedUtmCampaign('ton');
const hasUtmTerm = new HasUtmTerm();
const hasOfferId = new HasOfferId();
const hasProductName = new HasProductName();

hasCoupon
  .setNext(hasUtmSource)
  .setNext(hasRexUtmSource)
  .setNext(hasUtmMedium)
  .setNext(hasRexUtmMedium)
  .setNext(hasUtmCampaign)
  .setNext(hasRexUtmCampaign)
  .setNext(hasUtmTerm)
  .setNext(hasOfferId)
  .setNext(hasProductName);

export const rexChainSecondFlowValidator = hasCoupon;
