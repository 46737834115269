import dynamic from 'next/dynamic';

const FinanceStoneIcons1x = {
  calculator: dynamic(() => import('./Calculator')),
  'card-stone': dynamic(() => import('./CardStone')),
  'cent-clock': dynamic(() => import('./CentClock')),
  'chart-bar-2': dynamic(() => import('./ChartBar2')),
  'chart-bar-3': dynamic(() => import('./ChartBar3')),
  'dollar-off': dynamic(() => import('./DollarOff')),
  'dollar-phone': dynamic(() => import('./DollarPhone')),
  'dollar-invoice-pay': dynamic(() => import('./DollarInvoicePay')),
  'dollar-circle': dynamic(() => import('./DollarCircle')),
  'dollar-sign': dynamic(() => import('./DollarSign')),
  'e-commerce': dynamic(() => import('./Ecommerce')),
  'invoice-2': dynamic(() => import('./Invoice2')),
  money: dynamic(() => import('./Money')),
  'money-growth': dynamic(() => import('./MoneyGrowth')),
  'money-hand': dynamic(() => import('./MoneyHand')),
  pos: dynamic(() => import('./Pos')),
  'pos-coil': dynamic(() => import('./PosCoil')),
  'pos-small': dynamic(() => import('./PosSmall')),
  'rate-down': dynamic(() => import('./RateDown')),
  scan: dynamic(() => import('./Scan')),
  'stone-agent': dynamic(() => import('./StoneAgent')),
  'stone-agent-shaking-hands': dynamic(
    () => import('./StoneAgentShakingHands'),
  ),
  bank: dynamic(() => import('./Bank')),
  'card-credit': dynamic(() => import('./CardCredit')),
  'pos-qr': dynamic(() => import('./PosQr')),
  'card-in': dynamic(() => import('./CardIn')),
  'chart-small': dynamic(() => import('./ChartSmall')),
  'dollar-payment-link': dynamic(() => import('./DollarPaymentLink')),
  'card-hand': dynamic(() => import('./CardHand')),
  'dollar-shield': dynamic(() => import('./DollarShield')),
};

export default FinanceStoneIcons1x;
