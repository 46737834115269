import {
  HasCoupon,
  HasDefinedUtmCampaign,
  HasDefinedUtmMedium,
  HasDefinedUtmSource,
  HasUtmCampaign,
  HasUtmMedium,
  HasUtmSource,
  HasUtmTerm,
} from './chain-validators';

const hasCoupon = new HasCoupon();
const hasUtmSource = new HasUtmSource();
const hasMgmUtmSource = new HasDefinedUtmSource('mgm');
const hasUtmMedium = new HasUtmMedium();
const hasMgmUtmMedium = new HasDefinedUtmMedium('mgm');
const hasUtmCampaign = new HasUtmCampaign();
const hasMgmUtmCampaign = new HasDefinedUtmCampaign('stone');
const hasUtmTerm = new HasUtmTerm();

hasCoupon
  .setNext(hasUtmSource)
  .setNext(hasMgmUtmSource)
  .setNext(hasUtmMedium)
  .setNext(hasMgmUtmMedium)
  .setNext(hasUtmCampaign)
  .setNext(hasMgmUtmCampaign)
  .setNext(hasUtmTerm);

export const mgmChainValidator = hasCoupon;
