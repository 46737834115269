import { atom, selector } from 'recoil';

import IS_SERVER from '@/constants/isServer';
import { TChannelForm, TInboundAttendanceStatus } from '@/types/inbound';

const ATOM_PREFIX = 'inbound';

export const inboundChannelsState = atom<TChannelForm[]>({
  key: `${ATOM_PREFIX}/channels`,
  default: [],
});

export const inboundAttendanceStatusState = selector<TInboundAttendanceStatus>({
  key: `${ATOM_PREFIX}/attendanceIsAvaibleState`,
  get: ({ get }) => {
    const channels = get(inboundChannelsState);

    const channelsHasChat = channels.find(channel => channel === 'chat');
    const channelsHasWhatsapp = channels.find(
      channel => channel === 'whatsapp',
    );

    if (channelsHasWhatsapp || channelsHasChat) {
      return 'available';
    }

    return 'scheduled';
  },
});

export const hasPhoneChannelAvailableState = selector({
  key: `${ATOM_PREFIX}/hasPhoneChannelAvailableState`,
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let showPhoneByCampaign: any = true;
    const channels = get(inboundChannelsState);
    const channelsStatus = get(inboundAttendanceStatusState);

    const channelsHasPhone = channels.find(channel => channel === 'phone');

    const queryData: { key: string; value: string }[] = IS_SERVER
      ? []
      : JSON.parse(sessionStorage.getItem('queryData') || '[]');

    if (queryData && queryData.length > 0) {
      showPhoneByCampaign =
        Number(
          // eslint-disable-next-line array-callback-return
          queryData.findIndex(({ key, value }) => {
            key === 'utm_medium' && value === 'renda_ton';
          }),
        ) < 0;
    }

    return (
      showPhoneByCampaign &&
      (channelsHasPhone || channelsStatus === 'available')
    );
  },
});
