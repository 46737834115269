import dynamic from 'next/dynamic';

const ObjectsIcons1x = {
  'auto-truck': dynamic(() => import('./AutoTruck')),
  'auto-bus': dynamic(() => import('./AutoBus')),
  rings: dynamic(() => import('./Rings')),
  'date-calendar': dynamic(() => import('./DateCalendar')),
  'e-cart': dynamic(() => import('./ECart')),
  office: dynamic(() => import('./Office')),
  'date-clock': dynamic(() => import('./DateClock')),
  accelerator: dynamic(() => import('./Accelerator')),
  'book-open': dynamic(() => import('./BookOpen')),
};

export default ObjectsIcons1x;
