import IS_SERVER from '@/constants/isServer';

export const isInternalUrl = (host: string): boolean => {
  if (!IS_SERVER) {
    return host === window.location.host;
  }

  return false;
};

export const isExternalUrl = (host: string): boolean => {
  return !!host && !isInternalUrl(host);
};
