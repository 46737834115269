import dynamic from 'next/dynamic';

const FinanceStoneIcons2x = {
  'calculator-2x': dynamic(() => import('./Calculator')),
  'cent-clock-2x': dynamic(() => import('./CentClock')),
  'cent-hand-2x': dynamic(() => import('./CentHand')),
  'chart-small-2x': dynamic(() => import('./ChartSmall')),
  'dollar-payment-link-2x': dynamic(() => import('./DollarPaymentLink')),
  'dollar-invoice-pay-2x': dynamic(() => import('./DollarInvoicePay')),
  'dollar-sign-2x': dynamic(() => import('./DollarSign')),
  'dollar-phone-2x': dynamic(() => import('./DollarPhone')),
  'invoice-2-2x': dynamic(() => import('./Invoice2')),
  'money-hand-2x': dynamic(() => import('./MoneyHand')),
  'money-2x': dynamic(() => import('./Money')),
  'pos-2x': dynamic(() => import('./Pos')),
  'pos-qr-2x': dynamic(() => import('./PosQr')),
  'pos-receipt-list-2x': dynamic(() => import('./PosReceiptList')),
  'card-credit-2x': dynamic(() => import('./CardCredit')),
  'dollar-off-2x': dynamic(() => import('./DollarOff')),
  'card-in-2x': dynamic(() => import('./CardIn')),
  'rate-down-2x': dynamic(() => import('./RateDown')),
  'bank-2x': dynamic(() => import('./Bank')),
  'chart-bar-2-2x': dynamic(() => import('./ChartBar2')),
  'card-stone-2x': dynamic(() => import('./CardStone')),
  'card-hand-2x': dynamic(() => import('./CardHand')),
  'dollar-shield-2x': dynamic(() => import('./DollarShield')),
  'stone-agent-shaking-hands-2x': dynamic(
    () => import('./StoneAgentShakingHands'),
  ),
};

export default FinanceStoneIcons2x;
