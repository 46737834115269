import PremioIbest from '@/assets/icons/awards/white/premio-ibest.svg';
import PremioWhow from '@/assets/icons/awards/white/premio-whow.svg';
import RA1000 from '@/assets/icons/awards/white/RA_1000.svg';
import IconeBandeira from '@/assets/icons/credenciamento/cards/stone/icone-bandeira.svg';
import IconeBobina from '@/assets/icons/credenciamento/cards/stone/icone-bobina.svg';
import IconeCartao from '@/assets/icons/credenciamento/cards/stone/icone-cartao.svg';
import IconeCartao2 from '@/assets/icons/credenciamento/cards/stone/icone-cartão-2.svg';
import IconeCelular from '@/assets/icons/credenciamento/cards/stone/icone-celular.svg';
import IconeConfiguracao from '@/assets/icons/credenciamento/cards/stone/icone-configuracao.svg';
import IconeMaquininha from '@/assets/icons/credenciamento/cards/stone/icone-maquininha.svg';
import IconeRede from '@/assets/icons/credenciamento/cards/stone/icone-rede.svg';
import IconeWifi from '@/assets/icons/credenciamento/cards/stone/icone-wifi.svg';
import App from '@/assets/icons/features/desktop/App.svg';
import Atendimento from '@/assets/icons/features/desktop/Atendimento.svg';
import Entrega from '@/assets/icons/features/desktop/Entrega.svg';
import Pix from '@/assets/icons/features/desktop/Pix.svg';
import Recebimento from '@/assets/icons/features/desktop/Recebimento.svg';
import Software from '@/assets/icons/features/desktop/Software.svg';
import AppMobileAndTablet from '@/assets/icons/features/mobileAndTablet/App.svg';
import AtendimentoMobileAndTablet from '@/assets/icons/features/mobileAndTablet/Atendimento.svg';
import EntregaMobileAndTablet from '@/assets/icons/features/mobileAndTablet/Entrega.svg';
import PixMobileAndTablet from '@/assets/icons/features/mobileAndTablet/Pix.svg';
import RecebimentoMobileAndTablet from '@/assets/icons/features/mobileAndTablet/Recebimento.svg';
import SoftwareMobileAndTablet from '@/assets/icons/features/mobileAndTablet/Software.svg';
import AbaDesktop from '@/assets/icons/general/aba-desktop.svg';
import AbaMobile from '@/assets/icons/general/aba-mobile.svg';
import ArrowDownMobile from '@/assets/icons/general/arrow-down-mobile.svg';
import ArrowDown from '@/assets/icons/general/arrow-down.svg';
import ArrowLeftGreen from '@/assets/icons/general/arrow-left-green.svg';
import ArrowRightGreen from '@/assets/icons/general/arrow-right-green.svg';
import BChart from '@/assets/icons/general/b-chart.svg';
import BatteryCharging from '@/assets/icons/general/battery-charging.svg';
import CashPhoneGeneral from '@/assets/icons/general/cash-phone.svg';
import CheckCircle from '@/assets/icons/general/check-circle.svg';
import CheckGreen from '@/assets/icons/general/check-green.svg';
import ChrevonDown from '@/assets/icons/general/chevron-down.svg';
import ChevronDown2 from '@/assets/icons/general/chevron-down2.svg';
import ChevronRightGreen from '@/assets/icons/general/chevron-right-green.svg';
import Clock from '@/assets/icons/general/clock.svg';
import CloseGeneral from '@/assets/icons/general/close.svg';
import Coil from '@/assets/icons/general/coil.svg';
import CreditCard from '@/assets/icons/general/credit-card.svg';
import DChart from '@/assets/icons/general/d-chart.svg';
import BChartSmallIcon from '@/assets/icons/general/gray/b-chart-small.svg';
import CartIcon from '@/assets/icons/general/gray/cart.svg';
import CreditCardIcon from '@/assets/icons/general/gray/credit-card.svg';
import CustomerSupportIcon from '@/assets/icons/general/gray/customer-support.svg';
import HandshakeIcon from '@/assets/icons/general/gray/handshake.svg';
import LifeBuoyIcon from '@/assets/icons/general/gray/life-buoy.svg';
import MobileBankIcon from '@/assets/icons/general/gray/mobile-banking.svg';
import MobileCardIcon from '@/assets/icons/general/gray/mobile-card.svg';
import MoneyIcon from '@/assets/icons/general/gray/money.svg';
import PaymentLink from '@/assets/icons/general/gray/payment-link-menu.svg';
import PosSmallIcon from '@/assets/icons/general/gray/pos-small.svg';
import PosIcon from '@/assets/icons/general/gray/pos.svg';
import PixIcon from '@/assets/icons/general/gray/qr-code-pix-24px.svg';
import RaioXIcon from '@/assets/icons/general/gray/raio-x.svg';
import RingsIcon from '@/assets/icons/general/gray/rings.svg';
import RoundDollarIcon from '@/assets/icons/general/gray/round-dollar.svg';
import SwapHorizontalIcon from '@/assets/icons/general/gray/swap-horizontal.svg';
import UsersIcon from '@/assets/icons/general/gray/users.svg';
import Invoice2 from '@/assets/icons/general/invoice-2.svg';
import LogoWhatsappgeneral from '@/assets/icons/general/logo-whatsapp.svg';
import AgentStone from '@/assets/icons/general/machineFeatures/stone/agent-stone.svg';
import StoneLogo from '@/assets/icons/general/machineFeatures/stone/brand.svg';
import CustomerSupportStone from '@/assets/icons/general/machineFeatures/stone/customer-support.svg';
import HandHeart from '@/assets/icons/general/machineFeatures/stone/hand-heart.svg';
import MobilePhone from '@/assets/icons/general/machineFeatures/stone/mobile-phone.svg';
import QrCodePix from '@/assets/icons/general/machineFeatures/stone/qr-code-pix.svg';
import TagLoyalty from '@/assets/icons/general/machineFeatures/stone/tag-loyalty.svg';
import Brand from '@/assets/icons/general/machineFeatures/ton/brand.svg';
import CashPhone from '@/assets/icons/general/machineFeatures/ton/cash-phone.svg';
import CustomerSupportTon from '@/assets/icons/general/machineFeatures/ton/customer-support.svg';
import DollarSign from '@/assets/icons/general/machineFeatures/ton/dollar-sign.svg';
import Invoice from '@/assets/icons/general/machineFeatures/ton/invoice.svg';
import PosSmall from '@/assets/icons/general/machineFeatures/ton/pos-small.svg';
import ThumbsUp from '@/assets/icons/general/machineFeatures/ton/thumbs-up.svg';
import MobileBankingGeneral from '@/assets/icons/general/mobile-banking.svg';
import MoneyHand from '@/assets/icons/general/money-hand.svg';
import MoneyTime from '@/assets/icons/general/money-time.svg';
import ArrowLeft from '@/assets/icons/general/new/arrow-left.svg';
import ArrowRight from '@/assets/icons/general/new/arrow-right.svg';
import Bank from '@/assets/icons/general/new/bank.svg';
import BookOpen from '@/assets/icons/general/new/book-open.svg';
import ChevronsDown from '@/assets/icons/general/new/chevrons-down.svg';
import Handshake from '@/assets/icons/general/new/handshake.svg';
import Info from '@/assets/icons/general/new/info.svg';
import InfoSvg from '@/assets/icons/general/new/infoSvg.svg';
import MobileBanking from '@/assets/icons/general/new/mobile-banking.svg';
import Office from '@/assets/icons/general/new/office.svg';
import Quote from '@/assets/icons/general/new/quote.svg';
import Sign24 from '@/assets/icons/general/new/sign-24h.svg';
import UsersWm from '@/assets/icons/general/new/users-wm.svg';
import PaymentLinkGeneral from '@/assets/icons/general/payment-link.svg';
import Percent24px from '@/assets/icons/general/percent-24px.svg';
import PhoneQR from '@/assets/icons/general/phone-qr.svg';
import PlayButton from '@/assets/icons/general/play-button.svg';
import Pos1 from '@/assets/icons/general/pos-1.svg';
import PosQR from '@/assets/icons/general/pos-qr.svg';
import PosGeneral from '@/assets/icons/general/pos.svg';
import PotenciaStonev2 from '@/assets/icons/general/potencia-stone-v2.svg';
import QrCodePix24px from '@/assets/icons/general/qr-code-pix-24px.svg';
import QrCodePix40px from '@/assets/icons/general/qr-code-pix-40px.svg';
import Search from '@/assets/icons/general/search.svg';
import SimCard from '@/assets/icons/general/sim-card.svg';
import Support from '@/assets/icons/general/support.svg';
import SwapHorizontal from '@/assets/icons/general/swap-horizontal.svg';
import TagLoyaltyGeneral from '@/assets/icons/general/tag-loyalty.svg';
import ChevronLeft from '@/assets/icons/general/white/chevron-left.svg';
import ChevronsDownWhite from '@/assets/icons/general/white/chevrons-down.svg';
import Close from '@/assets/icons/general/white/close.svg';
import Copyright from '@/assets/icons/general/white/copyright.svg';
import MessageSquare from '@/assets/icons/general/white/message-square.svg';
import Wifi from '@/assets/icons/general/wifi.svg';
import Zap from '@/assets/icons/general/zap.svg';
import ArrowDownRight from '@/assets/icons/inbound/arrow-down-right.svg';
import HelpCircle from '@/assets/icons/inbound/help-circle.svg';
import LogoWhatsapp from '@/assets/icons/inbound/logo-whatsapp.svg';
import Message from '@/assets/icons/inbound/message.svg';
import Percent from '@/assets/icons/inbound/percent.svg';
import Phone from '@/assets/icons/inbound/phone.svg';
import Pos from '@/assets/icons/inbound/pos.svg';
import LinxLogoMobile from '@/assets/icons/linx/linx-logo-mobile.svg';
import LinxLogo from '@/assets/icons/linx/linx-logo.svg';
import Linx from '@/assets/icons/logos/linx.svg';
import StonecoLogos from '@/assets/icons/logos/stoneco.svg';
import TonAutocred from '@/assets/icons/logos/ton-autocred.svg';
import TonLogos from '@/assets/icons/logos/ton.svg';
import Pagarme from '@/assets/icons/otherProducts/pagarme.svg';
import Ton from '@/assets/icons/otherProducts/ton.svg';
import PosScrollingTicker from '@/assets/icons/pos/pos-scrolling-ticker.svg';
import Facebook2 from '@/assets/icons/socialMedia/facebook2.svg';
import Instagram2 from '@/assets/icons/socialMedia/instagram2.svg';
import LinkedIn2 from '@/assets/icons/socialMedia/linkedin2.svg';
import Twitter2 from '@/assets/icons/socialMedia/twitter2.svg';
import YouTube2 from '@/assets/icons/socialMedia/youtube2.svg';
import OneStar from '@/assets/icons/stars/one-star.svg';
import TwoStars from '@/assets/icons/stars/two-stars.svg';
import Chart from '@/assets/icons/stojis/black/chart.svg';
import Person from '@/assets/icons/stojis/black/person.svg';
import Brazil from '@/assets/icons/stojis/green/brazil.svg';
import Smile from '@/assets/icons/stojis/green/smile.svg';
import Wheel from '@/assets/icons/stojis/green/wheel.svg';
import BracesMobile from '@/assets/icons/stoneHistory/braces-mobile.svg';
import Braces from '@/assets/icons/stoneHistory/braces.svg';
import Potencia from '@/assets/icons/stoneLogo/potencia.svg';
import StoneLogo3Mobile from '@/assets/icons/stoneLogo/stone-logo-3-mobile.svg';
import StoneLogo3 from '@/assets/icons/stoneLogo/stone-logo-3.svg';
import StoneLogoIcon from '@/assets/icons/stoneLogo/stone-logo.svg';
import StoneMaisTon from '@/assets/icons/stoneLogo/stone-mais-ton.svg';
import Stoneco from '@/assets/icons/stoneLogo/stoneco.svg';
import DollarSignGeneral from '@/assets/icons/ton/general/dollar-sign.svg';
import LoanGeneral from '@/assets/icons/ton/general/loan.svg';
import LogoTonFace from '@/assets/icons/ton/general/logo-ton-face.svg';
import LogoTonStone from '@/assets/icons/ton/general/logo-ton-stone.svg';
import MobileChat from '@/assets/icons/ton/general/mobile-chat.svg';
import PosSmallTon from '@/assets/icons/ton/general/pos-small.svg';
import LogoMobile from '@/assets/icons/ton/logo-mobile.svg';
import Logo from '@/assets/icons/ton/logo.svg';
import BlackFridayIcon from '@/assets/icons/scrollingBar/black-friday-icon.svg';

export const listedIconsList = [
  PremioIbest,
  PremioWhow,
  RA1000,
  IconeBandeira,
  IconeBobina,
  IconeCartao2,
  IconeCartao,
  IconeCelular,
  IconeConfiguracao,
  IconeMaquininha,
  IconeRede,
  IconeWifi,
  App,
  Atendimento,
  Entrega,
  Pix,
  Recebimento,
  Software,
  AppMobileAndTablet,
  AtendimentoMobileAndTablet,
  EntregaMobileAndTablet,
  PixMobileAndTablet,
  RecebimentoMobileAndTablet,
  SoftwareMobileAndTablet,
  BChartSmallIcon,
  CartIcon,
  CreditCardIcon,
  CustomerSupportIcon,
  HandshakeIcon,
  LifeBuoyIcon,
  MobileBankIcon,
  MobileCardIcon,
  MoneyIcon,
  PaymentLink,
  PosSmallIcon,
  PosIcon,
  PixIcon,
  RaioXIcon,
  RingsIcon,
  RoundDollarIcon,
  SwapHorizontalIcon,
  UsersIcon,
  AgentStone,
  StoneLogo,
  CustomerSupportStone,
  HandHeart,
  MobilePhone,
  QrCodePix,
  TagLoyalty,
  Brand,
  CashPhone,
  CustomerSupportTon,
  DollarSign,
  Invoice,
  PosSmall,
  ThumbsUp,
  ArrowLeft,
  ArrowRight,
  Bank,
  BookOpen,
  ChevronsDown,
  Handshake,
  Info,
  InfoSvg,
  MobileBanking,
  Office,
  Quote,
  Sign24,
  UsersWm,
  ChevronLeft,
  ChevronsDownWhite,
  Close,
  Copyright,
  MessageSquare,
  AbaDesktop,
  AbaMobile,
  ArrowDownMobile,
  ArrowDown,
  ArrowLeftGreen,
  ArrowRightGreen,
  BChart,
  BatteryCharging,
  CashPhoneGeneral,
  CheckCircle,
  CheckGreen,
  ChrevonDown,
  ChevronDown2,
  ChevronRightGreen,
  Clock,
  CloseGeneral,
  Coil,
  CreditCard,
  DChart,
  DollarSignGeneral,
  Invoice2,
  LoanGeneral,
  LogoWhatsappgeneral,
  MobileBankingGeneral,
  MoneyHand,
  MoneyTime,
  PaymentLinkGeneral,
  Percent24px,
  PhoneQR,
  PlayButton,
  Pos1,
  PosQR,
  PosGeneral,
  PotenciaStonev2,
  QrCodePix24px,
  QrCodePix40px,
  Search,
  SimCard,
  Support,
  SwapHorizontal,
  TagLoyaltyGeneral,
  Wifi,
  Zap,
  ArrowDownRight,
  HelpCircle,
  LogoWhatsapp,
  Message,
  Percent,
  Phone,
  Pos,
  LinxLogoMobile,
  LinxLogo,
  Linx,
  StonecoLogos,
  TonAutocred,
  TonLogos,
  Pagarme,
  Ton,
  PosScrollingTicker,
  Facebook2,
  Instagram2,
  LinkedIn2,
  Twitter2,
  YouTube2,
  OneStar,
  TwoStars,
  Person,
  Chart,
  Smile,
  Brazil,
  Wheel,
  BracesMobile,
  Braces,
  Potencia,
  StoneLogo3Mobile,
  StoneLogo3,
  StoneLogoIcon,
  StoneMaisTon,
  Stoneco,
  LogoTonFace,
  LogoTonStone,
  MobileChat,
  PosSmallTon,
  Logo,
  LogoMobile,
  BlackFridayIcon,
];
