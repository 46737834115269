export enum Company {
  Ton = 'TON',
  Stone = 'STONE',
}

export const tpvOptions = [
  {
    company: Company.Ton,
    label: 'Até R$ 10.000',
    value: 'Até R$ 10.000',
    int: 1_000_000,
  },
  {
    company: Company.Ton,
    label: 'Entre R$ 10.000 e R$ 15.000',
    value: 'Entre R$ 10.000 e R$ 15.000',
    int: 1_500_000,
  },
  {
    company: Company.Stone,
    label: 'Entre R$ 15.000 e R$ 30.000',
    value: 'Entre R$ 15.000 e R$ 30.000',
    int: 3_000_000,
  },
  {
    company: Company.Stone,
    label: 'Entre R$ 30.000 e R$ 200.000',
    value: 'Entre R$ 30.000 e R$ 200.000',
    int: 20_000_000,
  },
  {
    company: Company.Stone,
    label: 'Acima de R$ 200.000',
    value: 'Acima de R$ 200.000',
    int: 999_999_999,
  },
];
export const translateTpvOption = (tpvValue: string) => {
  const findItem = tpvOptions.find(item => tpvValue === item.value);
  return findItem?.int;
};
