import { useEffect, useState } from 'react';
import useStore from '@/store';

import IS_SERVER from '@/constants/isServer';
import getUtmUrl from '@/utils/getUtmUrl';

import { mgmChainValidator } from '@/helpers/mgm/mgmChainValidator';

export const useMgm = () => {
  const [isMgm, setIsMgm] = useState(false);
  const { updateWithUtmParams } = useStore();

  useEffect(() => {
    if (!IS_SERVER) {
      const data = getUtmUrl();

      if (mgmChainValidator.handle(data)) {
        setIsMgm(true);

        updateWithUtmParams(data);
      } else {
        setIsMgm(false);
      }
    }
  }, [updateWithUtmParams]);

  return [isMgm];
};
