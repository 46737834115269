const GA_SETTINGS = {
  UACode: 'UA-104644236-1', // TODO: verficar qual é o 'GA_MEASUREMENT_ID' parameter
  debug: false,
  gaOptions: {
    // siteSpeedSampleRate: 10, // TODO: taxa de captura (100 = 100% dos browsers)
    allowLinker: true,
  },
};

const GTM_SETTINGS = {
  gtmId: 'GTM-NQ2W52T',
};

export { GA_SETTINGS, GTM_SETTINGS };
