'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  hasPhoneChannelAvailableState,
  inboundAttendanceStatusState,
  inboundChannelsState,
} from '@/containers/InboundStone/atoms';
import { loadTwilio } from '@/scripts/twillio';
import { getInboundAvailableChannels } from '@/services/inbound';
import { TChannelForm, TInboundAttendanceStatus } from '@/types/inbound';

type InboundContextType = {
  channels: TChannelForm[];
  attendanceStatus: TInboundAttendanceStatus;
  isLoading: boolean;
  hasPhoneChannelAvailable: boolean;
  defaultChannel?: TChannelForm;
  hasChannelAvailable: (channel: TChannelForm) => boolean;
};

const InboundContextDefaultValues: InboundContextType = {
  channels: [],
  attendanceStatus: 'scheduled',
  isLoading: false,
  hasPhoneChannelAvailable: false,
  hasChannelAvailable: () => false,
};

const InboundContext = createContext<InboundContextType>(
  InboundContextDefaultValues,
);

export const useInbound = (): InboundContextType => {
  return useContext(InboundContext);
};

export const InboundProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const channels = useRecoilValue(inboundChannelsState);
  const attendanceStatus = useRecoilValue(inboundAttendanceStatusState);
  const hasPhoneChannelAvailable = useRecoilValue(
    hasPhoneChannelAvailableState,
  );

  const defaultChannel = useMemo(() => {
    return searchParams?.get('defaultChannel') as TChannelForm; // Acessando a query diretamente
  }, [searchParams]);

  const setChannels = useSetRecoilState(inboundChannelsState);

  const fetchAvailableChannels = useCallback(async () => {
    const fetchedChannels = await getInboundAvailableChannels();

    setChannels(fetchedChannels);
    setIsLoading(false);
  }, [setChannels]);

  const hasChannelAvailable = useCallback(
    (askedChannel: TChannelForm) => {
      return !!channels.find(channel => channel === askedChannel);
    },
    [channels],
  );

  useEffect(() => {
    fetchAvailableChannels();
  }, [fetchAvailableChannels]);

  useEffect(() => {
    setTimeout(() => {
      if (pathname && pathname.includes('/adquira')) {
        loadTwilio();
      }
    }, 4000);
  }, [pathname]);

  return (
    <>
      <InboundContext.Provider
        value={{
          channels,
          attendanceStatus,
          isLoading,
          defaultChannel,
          hasPhoneChannelAvailable,
          hasChannelAvailable,
        }}
      >
        {children}
      </InboundContext.Provider>
    </>
  );
};
