const translateTpvOptions = (selectedTpv: string) => {
  const selectedTpvLower = selectedTpv.toLocaleLowerCase();

  let selectedTpvId: number;
  switch (selectedTpvLower) {
    case 'até r$ 10.000,00':
    case 'até r$ 10.000':
      selectedTpvId = 0;
      break;
    case 'entre r$ 10.000,00 e r$ 15.000,00':
    case 'entre r$ 10.000 e r$ 15.000':
    case 'entre r$ 10.000 e r$ 20.000':
    case 'entre r$ 10.000,00 e r$ 20.000,00':
    case 'até r$ 15.000':
      selectedTpvId = 1;
      break;
    case 'entre r$ 15.000 e r$ 30.000':
    case 'entre r$ 20.000 e r$ 50.000':
    case '+ de r$ 15.000':
      selectedTpvId = 2;
      break;
    case 'entre r$ 30.000 e r$ 200.000':
    case 'entre r$ 50.000 e r$ 200.000':
    case '+ de r$ 30.000':
      selectedTpvId = 3;
      break;
    case 'acima de r$ 200.000':
      selectedTpvId = 4;
      break;
    default:
      throw new Error('Nenhum TPV foi selecionado');
  }

  return selectedTpvId;
};

export default translateTpvOptions;
