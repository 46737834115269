import { illustrationsList } from '@/assets/illustrations/index';

export const IllustrationsList = () => {
  return (
    <div className="grid overflow-y-scroll grid-cols-5 gap-36">
      {illustrationsList.map(Item => (
        <div className="flex flex-col justify-center items-center w-full">
          <Item />
          <p>{Item.name.replace('Svg', '')}</p>
        </div>
      ))}
    </div>
  );
};
