import dynamic from 'next/dynamic';

const SecurityTechnology2x = {
  'qr-code-2x': dynamic(() => import('./QrCode')),
  'security-shield-2x': dynamic(() => import('./SecurityShield')),
  'signal-contactless-2x': dynamic(() => import('./SignalContactless')),
  'zap-off-2x': dynamic(() => import('./ZapOff')),
  'phone-card-2x': dynamic(() => import('./PhoneCard')),
  'phone-smart-2x': dynamic(() => import('./PhoneSmart')),
  'tool-settings-2x': dynamic(() => import('./ToolSettings')),
  'link-2x': dynamic(() => import('./Link')),
  'phone-chat-2x': dynamic(() => import('./PhoneChat')),
  'security-key-2x': dynamic(() => import('./SecurityKey')),
  'file-2x': dynamic(() => import('./File')),
  'security-lock-2x': dynamic(() => import('./SecurityLock')),
  'phone-2x': dynamic(() => import('./Phone')),
  'help-info-2x': dynamic(() => import('./HelpInfo')),
};

export default SecurityTechnology2x;
