/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface AutocredIndex {
  offer_id: string;
  plan_name: string;
  planIndex: number;
}

interface AutocredPlanos {
  plan_name: string;
  offer_id: string;
  standard_machine_price: number;
  standard_machine_installments: { quantity: number; value: number };
  smart_machine_price: number;
  smart_machine_installments: { quantity: number; value: number };
}

interface AutocredMaquininhas {
  cart_id: string;
  plan_id: string;
  subtotal: number;
  total_discount: number;
  total: number;
  standard_machine_quantity: number;
  smart_machine_quantity: number;
  installments: {
    quantity: number;
    value: number;
  };
}

interface AutocredDados {
  user: {
    document: string;
    email: string;
    full_name: string;
    phone: string;
  };
  organization: {
    document: string;
    legal_name: string;
  };
}

interface AutocredEntrega {
  postal_code: string;
  street: string;
  street_number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  reference_point: string;
  extra: string;
}

interface AutoCredMGMRendaExtra {
  coupon: string;
}

interface UtmParams {
  client_id: string;
  fbid: string;
  gcid: string;
  user_id: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_placement: string;
  utm_source: string;
  utm_term: string;
  utm_mgm: string;
}

export interface InitialMachinePrices {
  offer_id?: string;
  plan_name?: string;
  standard_machine_price: number;
  standard_machine_installments?: { quantity: number; value: number };
  smart_machine_price: number;
  smart_machine_installments?: { quantity: number; value: number };
}

//TODO: Rever tipo Record
export interface IAutoCredState
  extends AutocredIndex,
    AutocredPlanos,
    AutocredDados,
    AutocredEntrega,
    AutoCredMGMRendaExtra,
    AutocredMaquininhas,
    Partial<UtmParams> {
  data: Record<string, string | never>;
  updateData: (data: Record<string, string | never>, key?: string) => void;
  updateInitialMachinesPricesAndMachineName: (
    data: InitialMachinePrices,
  ) => void;
  updateTotal(data: {
    total: number;
    subtotal: number;
    total_discount: number;
    installments: { quantity: number; value: number };
  }): void;
  updateWithIndexData: (data: AutocredIndex) => void;
  updateStandardMachineQuantity: (quantity: number) => void;
  updateSmartMachineQuantity: (quantity: number) => void;
  updateWithPlanosData: (data: AutocredPlanos) => void;
  updateWithMaquininhas: (data: AutocredMaquininhas) => void;
  updateWithDadosData: (data: AutocredDados) => void;
  updateWithEntregaData: (data: AutocredEntrega) => void;
  updateWithMGMRendaExtra: (data: AutoCredMGMRendaExtra) => void;
  updateWithUtmParams: (data: Partial<UtmParams>) => void;
  removerCupom: () => void;
  updateCoupon: (coupon: string) => void;
}

//TODO: Remover ts-ignore
export default create<IAutoCredState>()(
  devtools(
    persist(
      set => ({
        data: {},
        plan_name: '',
        planIndex: 0,
        offer_id: '',
        user: {
          document: '',
          email: '',
          full_name: '',
          phone: '',
        },
        organization: {
          document: '',
          legal_name: '',
        },
        postal_code: '',
        street: '',
        street_number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        reference_point: '',
        extra: '',
        installments: {
          quantity: 0,
          value: 0,
        },
        cart_id: '',
        plan_id: '',
        standard_machine_quantity: 1,
        smart_machine_quantity: 1,
        subtotal: 0,
        total: 0,
        total_discount: 0,
        standard_machine_price: 0,
        standard_machine_installments: { quantity: 0, value: 0 },
        smart_machine_price: 0,
        smart_machine_installments: { quantity: 0, value: 0 },
        client_id: '',
        fbid: '',
        gcid: '',
        user_id: '',
        utm_campaign: '',
        utm_content: '',
        utm_medium: '',
        utm_placement: '',
        utm_source: '',
        utm_term: '',
        coupon: '',
        updateData: (data, key) => {
          if (key) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            set(state => ({
              ...state,
              data: {
                ...state.data,
                [key]: data,
              },
            }));
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            set(state => ({
              ...state,
              data: {
                ...state.data,
                ...data,
              },
            }));
          }
        },
        updateWithIndexData(data) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateInitialMachinesPricesAndMachineName(data: InitialMachinePrices) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateTotal(data) {
          set(state => ({
            ...state,
            ...data,
            installments: {
              quantity: data.installments.quantity,
              value: data.installments.value,
            },
          }));
        },
        updateStandardMachineQuantity(quantity) {
          set(state => ({
            ...state,
            standard_machine_quantity: quantity,
          }));
        },
        updateSmartMachineQuantity(quantity) {
          set(state => ({
            ...state,
            smart_machine_quantity: quantity,
          }));
        },
        updateWithPlanosData(data: AutocredPlanos) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateWithDadosData(data: AutocredDados) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateWithEntregaData(data: AutocredEntrega) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateWithMGMRendaExtra(data: AutoCredMGMRendaExtra) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateWithMaquininhas(data: AutocredMaquininhas) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        updateWithUtmParams(data: Partial<UtmParams>) {
          set(state => ({
            ...state,
            ...data,
          }));
        },
        removerCupom() {
          set(state => ({
            ...state,
            coupon: '',
          }));
        },
        updateCoupon(coupon: string) {
          set(state => ({
            ...state,
            coupon,
          }));
        },
      }),
      {
        name: 'auto-cred',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);
