interface CheckPathAccessProps {
  pathname: string;
  pathRestrictions: string;
}
export const normalizedPath = (pathname: string) => {
  return pathname.replace(/\/$/, '');
};

export const checkPathAccess = ({
  pathname,
  pathRestrictions,
}: CheckPathAccessProps) => {
  const currentPath = normalizedPath(pathname);
  return [pathRestrictions].includes(currentPath);
};
