'use client';

import React, { useEffect, useMemo, useState } from 'react';

import Button from '@/components/Button';
import Icon, { IconsNames } from '@/components/v3/Icon';
import { TChannelForm } from '@/types/inbound';

type FinishMessagesProps = {
  onClose: () => void;
  channel: TChannelForm;
  hideCloseButton?: boolean;
  nameButton?: string;
};

const channelsText: Partial<
  Record<
    TChannelForm,
    {
      title: string;
      subTitle?: string;
      icon: IconsNames;
      complement?: JSX.Element;
    }
  >
> = {
  whatsapp: {
    icon: 'animation/whatsapp',
    title: 'Você receberá uma mensagem agora no seu WhatsApp!',
    subTitle:
      'Responda a nossa mensagem para que possamos conversar o quanto antes sobre o seu negócio.',
  },
  'whatsapp-out-business-hours': {
    icon: 'animation/whatsapp',
    title: 'Estamos fora do horário de atendimento',
    subTitle: 'Entraremos em contato com você assim que começarmos a atender.',
    complement: (
      <div className="flex flex-col gap-8 mt-16 w-full">
        <p className="font-semibold text-center text-display-900 paragraph-16">
          Horário de atendimento:
        </p>
        <ul className="flex flex-col gap-8 items-center">
          <li className="flex gap-8 items-center text-center">
            <Icon
              name="arrow-fill-left"
              className="text-display-900 fill-current"
            />
            Segunda a Sexta das 6h às 00h
          </li>
          <li className="flex gap-8 items-center text-center">
            <Icon
              name="arrow-fill-left"
              className="text-display-900 fill-current"
            />
            Finais de semana e feriados das 7h às 19h
          </li>
        </ul>
      </div>
    ),
  },
  chat: {
    icon: 'animation/chat',
    title: 'Você será atendido agora!',
    subTitle:
      'Fale com a gente através do chat localizado no canto inferior direito da sua tela',
  },
  'phone-c2c': {
    icon: 'animation/phone',
    title:
      'Agora é com a gente! Em breve você receberá uma ligação do nosso time',
  },
};

const FinishMessages: React.FC<FinishMessagesProps> = ({
  onClose,
  channel,
  hideCloseButton,
}) => {
  const text = useMemo(() => channelsText[channel], [channel]);
  const [messageSubmit, setMessageSubmit] = useState(false);

  useEffect(() => {
    setMessageSubmit(true);

    setTimeout(() => {
      setMessageSubmit(false);
    }, 4000);
  }, []);

  return (
    <div className="flex relative flex-col justify-center items-center pt-80 sm:pt-0 max-w-[448px] h-full sm:h-auto text-center">
      {!hideCloseButton && (
        <button
          type="button"
          onClick={onClose}
          className="absolute top-0 sm:top-0 right-0 sm:-right-40 p-8 sm:mt-[-8px] ml-auto w-40 h-40 bg-transparent rounded-full"
          disabled={messageSubmit}
        >
          <Icon name="close" className="text-display-900 fill-current" />
        </button>
      )}
      <div className="flex justify-center w-full">
        {text && <Icon name={text.icon} className="w-80 h-80" />}
      </div>
      <h5 className="mt-16 font-bold heading-3">{text?.title}</h5>
      {text?.subTitle && <p className="mt-8 paragraph-16">{text?.subTitle}</p>}
      {text?.complement && text.complement}
      {!hideCloseButton && (
        <Button
          id="button-redirect-home"
          type="button"
          color="secondary"
          onClick={onClose}
          className="mt-auto sm:mt-24 w-full"
          disabled={messageSubmit}
        >
          Voltar para o site
        </Button>
      )}
    </div>
  );
};

export default FinishMessages;
