type FechMethod = 'DELETE' | 'GET' | 'POST' | 'PUT';

export async function fetcher<T>(
  baseUrl: string,
  method: FechMethod,
  bodyContent?: object,
  headerContent?: object,
) {
  if (!bodyContent || !headerContent)
    return fetch(baseUrl, { method }).then(res => res.json()) as Promise<T>;

  const body = JSON.stringify(bodyContent);
  const headers = new Headers(headerContent as HeadersInit);

  return fetch(baseUrl, { method, body, headers }).then(res => {
    if (res) return res.json();
  }) as Promise<T>;
}
