import { InboundType } from '@/containers/InboundStone/interface';

export async function sendInboundValues(
  httpClient: (endpoint: string, options?: RequestInit) => Promise<Response>,
  data: InboundType,
) {
  const formattedData = {
    ...data,
    document: data.document ? data.document.replace(/[^a-z\d\s]+/gi, '') : '',
  };

  const response = await httpClient('/inbounds', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formattedData),
  });

  if (!response.ok) {
    throw new Error(`Failed to send inbound values: ${response.statusText}`);
  }

  return response;
}
