import { IPlan } from '@/containers/Plans/components/Plans/planType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createQueryString = (params: Record<string, any>) => {
  const query = new URLSearchParams(params).toString();
  return query ? `?${query}` : '';
};

export const getStonePlans = async (cache?: boolean): Promise<IPlan[]> => {
  try {
    const params = {
      'plan-name-formatted': true,
      recaptcha: process.env.NEXT_PUBLIC_RECAPTCHA_BYPASS,
    };

    const queryString = createQueryString(params);
    const response = await fetch(
      `${process.env.AUTOCRED_BFF_URL}/offers${queryString}`,
      {
        cache: cache ? 'default' : 'no-store',
        method: 'GET',
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error('Failed to fetch Stone Plans:', error);
    return [];
  }
};
