import dynamic from 'next/dynamic';

const ArrowIcons2x = {
  'arrow-left-2x': dynamic(() => import('./ArrowLeft')),
  'arrow-right-2x': dynamic(() => import('./ArrowRight')),
  'arrow-swap-horizontal-2x': dynamic(() => import('./ArrowSwapHorizontal')),
  'chevron-Right-2x': dynamic(() => import('./ChevronRight')),
  'arrow-down-2x': dynamic(() => import('./ArrowDown')),
} as const;

export default ArrowIcons2x;
