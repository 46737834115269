'use client';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import InboundDrawer from '@/containers/InboundStone/components/InboundDrawer';
import { loadTwilio } from '@/scripts/twillio';

type InboundDrawerContextType = {
  toggleDrawer: () => void;
  isVisible: boolean;
};

const InboundDrawerContextDefaultValues: InboundDrawerContextType = {
  toggleDrawer: () => undefined,
  isVisible: false,
};

const InboundDrawerContext = createContext<InboundDrawerContextType>(
  InboundDrawerContextDefaultValues,
);

export const useInboundDrawer = (): InboundDrawerContextType => {
  return useContext(InboundDrawerContext);
};

export const InboundDrawerProvider: React.FC<{
  children: React.ReactNode;
  pathname: string;
}> = ({ children, pathname }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleDrawer = useCallback(() => {
    setIsVisible(value => !value);
  }, []);

  useEffect(() => {
    if (isVisible) {
      loadTwilio({
        loadRcFlow: false,
      });
    }
  }, [isVisible]);

  return (
    <InboundDrawerContext.Provider
      value={{
        toggleDrawer,
        isVisible,
      }}
    >
      {isVisible && <InboundDrawer pathname={pathname} />}
      {children}
    </InboundDrawerContext.Provider>
  );
};
