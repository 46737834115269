import dynamic from 'next/dynamic';

const SecurityTechnology1x = {
  apps: dynamic(() => import('./Apps')),
  'battery-charging': dynamic(() => import('./BatteryCharging')),
  phone: dynamic(() => import('./Phone')),
  'phone-mobile': dynamic(() => import('./PhoneMobile')),
  'qr-code': dynamic(() => import('./QrCode')),
  'signal-loader': dynamic(() => import('./SignalLoader')),
  'signal-wifi-2': dynamic(() => import('./SignalWifi2')),
  'sim-card': dynamic(() => import('./SimCard')),
  tool: dynamic(() => import('./Tool')),
  'audio-video-play-circle': dynamic(() => import('./AudioVideoPlayCircle')),
  'phone-outgoing': dynamic(() => import('./PhoneOutgoing')),
  file: dynamic(() => import('./File')),
  'tool-settings': dynamic(() => import('./ToolSettings')),
  'phone-card': dynamic(() => import('./PhoneCard')),
  zap: dynamic(() => import('./Zap')),
  link: dynamic(() => import('./Link')),
  'phone-chat': dynamic(() => import('./PhoneChat')),
  'security-shield': dynamic(() => import('./SecurityShield')),
  'signal-contactless': dynamic(() => import('./SignalContactless')),
  'security-key': dynamic(() => import('./SecurityKey')),
  'security-lock': dynamic(() => import('./SecurityLock')),
  'help-info': dynamic(() => import('./HelpInfo')),
};

export default SecurityTechnology1x;
