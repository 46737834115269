import ApiConnect from '@/assets/illustrations/devcenter/apiConnect.svg';
import AutoTEF from '@/assets/illustrations/devcenter/autoTEF.svg';
import Conciliacao from '@/assets/illustrations/devcenter/conciliacao.svg';
import SdkAndroid from '@/assets/illustrations/devcenter/sdkAndroid.svg';
import RA1000CertIcon from '@/assets/illustrations/ra1000/certificado-RA1000.svg';
import RA1000MiniIcon from '@/assets/illustrations/ra1000/selo-RA1000-mini.svg';
import RA1000Icon from '@/assets/illustrations/ra1000/selo-RA1000.svg';
import WhatsAppTalk from '@/assets/illustrations/whatsapp/talk.svg';
import LikeCopy from '@/assets/illustrations/like-copy.svg';
import Like from '@/assets/illustrations/like.svg';
import Chat from '@/assets/illustrations/chat.svg';

export const illustrationsList = [
  Like,
  LikeCopy,
  //devcenter
  ApiConnect,
  AutoTEF,
  Conciliacao,
  SdkAndroid,
  //ra1000
  RA1000CertIcon,
  RA1000MiniIcon,
  RA1000Icon,
  //whatsapp
  WhatsAppTalk,
  Chat,
];
