import { TChannelForm } from '@/types/inbound';

import {
  getBusinessHoursChannels,
  getInboundChannels,
} from './getBusinessHoursChannels';

export const getInboundAvailableChannels = async (): Promise<
  TChannelForm[]
> => {
  const [channels, inboundChannels] = await Promise.all([
    getBusinessHoursChannels(),
    getInboundChannels(),
  ]);

  const hasWhatsappOnChannels = channels.find(
    channel => channel === 'whatsapp',
  );

  const hasChatOnChannels = channels.find(channel => channel === 'chat');

  const hasMobileOnInboundChannels = inboundChannels.find(
    channel => channel === 'mobile',
  );

  if (!hasChatOnChannels && !hasWhatsappOnChannels) {
    channels.push('phone-c2c');
  }

  if (hasMobileOnInboundChannels) {
    channels.push('phone');
  }

  return channels as TChannelForm[];
};
