import dynamic from 'next/dynamic';

const EmotionsHealthIcons1x = {
  'hand-heart': dynamic(() => import('./HandHeart')),
  hospital: dynamic(() => import('./Hospital')),
  pill: dynamic(() => import('./Pill')),
  puzzle: dynamic(() => import('./Puzzle')),
  'hand-shake': dynamic(() => import('./HandShake')),
  'face-happy': dynamic(() => import('./FaceHappy')),
};

export default EmotionsHealthIcons1x;
