'use client';

import React, { createContext, useContext, ReactNode, useState } from 'react';

interface ShowCalculatorProps {
  children: ReactNode;
}

interface ShowCalculatorContextType {
  showComponent: boolean;
  setShowComponentCalculator: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShowCalculator = createContext<ShowCalculatorContextType>(
  {} as ShowCalculatorContextType,
);

export const useShowCalculator = (): ShowCalculatorContextType => {
  const context = useContext(ShowCalculator);
  if (context === undefined) {
    throw new Error(
      'useShowCalculator must be used within a ShowCalculatorProvider',
    );
  }
  return context;
};

export const ShowCalculatorProvider: React.FC<ShowCalculatorProps> = ({
  children,
}) => {
  const [showComponent, setShowComponentCalculator] = useState<boolean>(true);

  const value: ShowCalculatorContextType = {
    showComponent,
    setShowComponentCalculator,
  };

  return (
    <ShowCalculator.Provider value={value}>{children}</ShowCalculator.Provider>
  );
};
