/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { Field, FieldProps, Form, Formik } from 'formik';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';

import Button from '@/components/Button';
import { If } from '@/components/UtilitiesComponents';
import { AmplitudeContext } from '@/contexts/amplitude';
import { useMgm } from '@/hooks/useMgm';
import { defaultChannelsOrder, tpvOptions } from '@/resources/inbound';
import { validateIsMGM } from '@/services/validateMgmRendaExtra';
import useStore from '@/store';
import { TChannelForm } from '@/types/inbound';
import handleHash from '@/utils/handleHash';
import { cnpjMask, dontAllowWhitespaceMask, phoneMask } from '@/utils/masks';

import { schema } from './validation';

const IconWhatsapp = dynamic(
  () => import('@/components/v3/Icon/icons/Whatsapp'),
);

const FormikSelectInput = dynamic(
  () =>
    /* webpackChunkName: "FormikSelectInput" */
    import('./components/FormikSelectInput'),
);

const FormikTextField = dynamic(() => import('./components/FormikTextField'));

export type TInboundFormData = {
  channel: TChannelForm;
  name: string;
  phone: string;
  email: string;
  document: string;
  tpv: string;
  leadSourceGroup?: string;
  cupom?: string;
  isPlanPage?: boolean;
  hasSmbCnpj?: boolean;
  isSmbPage?: boolean;
};

type InboundFormProps = {
  channels: TChannelForm[];
  pathname: string;
  onSubmit: (data: TInboundFormData, {}: any) => void;
  submitText?: string;
  defaultChannel?: TChannelForm;
  onFinishModalJustClose?: boolean;
  showFinishModalOnlyAtChat?: boolean;
  analyticsSectionReference?: string;
  analyticsEventType?: string;
};

const InboundForm: React.FC<InboundFormProps> = ({
  channels,
  onSubmit,
  defaultChannel,
  pathname,
  analyticsSectionReference,
  analyticsEventType = 'generate_lead',
}) => {
  const { analytics } = useContext(AmplitudeContext);
  const [isSmb, setIsSmb] = useState(false);
  const [hasMgmProps, setHasMgmProps] = useState(false);

  const [isMgm] = useMgm();
  const store = useStore();
  const validateIfIsMgm = isMgm || hasMgmProps;

  useEffect(() => {
    setIsSmb(pathname.includes('/adquira-smb'));
  }, [pathname]);

  useEffect(() => {
    setHasMgmProps(validateIsMGM(store));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.utm_source, store.utm_medium, store.utm_campaign]);

  const formInitialValues = {
    name: store.user?.full_name,
    email: store.user?.email,
    phone: store.user?.phone,
    document: '',
    tpv: '',
  };

  const filteredChannels = useMemo(
    () => channels.filter(channel => channel !== 'phone'),
    [channels],
  );

  const defaultChannelValue = useMemo(() => {
    if (defaultChannel && filteredChannels.includes(defaultChannel))
      return defaultChannel;

    return defaultChannelsOrder.find(
      defaultChannelOrder => !!filteredChannels.includes(defaultChannelOrder),
    );
  }, [defaultChannel, filteredChannels]);

  const trackOnBlurFields = async (name: string, value: string) => {
    analytics?.track({
      event_type: `step ${name} completed`,
      event_properties: {
        [`input_${name}`]: await handleHash(value),
        name: `step ${name} completed`,
        description: `Evento disparado quando o usuário preenche o ${name}. Como propriedade será retornada qual o ${name} digitado`,
      },
    });
  };

  const trackCtaTestAdquira = (cta: string | null) => {
    const selectTpv = () => {
      return document.querySelector('#tpv')?.textContent;
    };

    analytics?.track({
      event_type: analyticsEventType,
      event_properties: {
        value: selectTpv(),
        currency: 'BRL',
        name: analyticsEventType,
        description: `Evento disparado quando o usuario envia o formulario do adquira.`,
        cta_reference: cta,
        section_reference: analyticsSectionReference,
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formInitialValues,
        channel: defaultChannelValue ?? 'chat',
      }}
      validationSchema={schema(validateIfIsMgm || isSmb)}
      onSubmit={onSubmit}
      validateOnMount={false}
    >
      {props => {
        const isButtonDisabled = !props.isValid;
        if (props.isSubmitting && props.values.channel === 'whatsapp') {
          typeof localStorage !== 'undefined' &&
            localStorage.removeItem('twilio-flex-cf');
        }

        return (
          <>
            <Form
              onSubmit={props.handleSubmit}
              className="grid gap-16 w-full"
              name="Inbound"
            >
              <If condition={isSmb}>
                <Field id="tpv" name="tpv" aria-label="Faturamento mensal">
                  {(fieldProps: FieldProps) => (
                    <FormikSelectInput
                      label="Faturamento mensal em cartão"
                      description="Estimativa de vendas feitas em cartão de crédito e débito."
                      options={tpvOptions}
                      iid="tpvSelect"
                      id="react-select-tpvSelect-input"
                      aria-label="Faturamento mensal"
                      name={fieldProps.field.name}
                      {...fieldProps}
                      onBlurCallBack={(option: OptionTypeBase) => {
                        const name = 'revenue';
                        const value = option.value;

                        analytics?.track({
                          event_type: `step ${name} completed`,
                          event_properties: {
                            [`input_${name}`]: value,
                            name: `step ${name} completed`,
                            description:
                              'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                          },
                        });
                      }}
                    />
                  )}
                </Field>
              </If>

              <FormikTextField
                name="name"
                label="Nome Completo"
                maskFunction={dontAllowWhitespaceMask}
                onBlurCallBack={(
                  event: React.FocusEvent<HTMLInputElement, Element>,
                ) => {
                  if (event.target.value) {
                    trackOnBlurFields(event.target.name, event.target.value);
                  }
                }}
              />
              <FormikTextField
                type="tel"
                name="phone"
                label="Celular com DDD"
                maskFunction={phoneMask}
                onBlurCallBack={(
                  event: React.FocusEvent<HTMLInputElement, Element>,
                ) => {
                  if (event.target.value) {
                    trackOnBlurFields(event.target.name, event.target.value);
                  }
                }}
              />
              <FormikTextField
                type="email"
                name="email"
                label="E-mail"
                onBlurCallBack={(
                  event: React.FocusEvent<HTMLInputElement, Element>,
                ) => {
                  if (event.target.value) {
                    trackOnBlurFields(event.target.name, event.target.value);
                  }
                }}
              />
              <If condition={validateIfIsMgm || isSmb}>
                <div className="flex flex-col gap-8">
                  <FormikTextField
                    type="text"
                    name="document"
                    label="CNPJ"
                    aria-label="CNPJ"
                    maskFunction={cnpjMask}
                    onBlurCallBack={(
                      event: React.FocusEvent<HTMLInputElement, Element>,
                    ) => {
                      trackOnBlurFields(event.target.name, event.target.value);
                    }}
                  />
                </div>
              </If>
              <If condition={!isSmb}>
                <Field id="tpv" name="tpv" aria-label="Faturamento mensal">
                  {(fieldProps: FieldProps) => (
                    <FormikSelectInput
                      label="Faturamento mensal em cartão"
                      description="Estimativa de vendas feitas em cartão de crédito e débito."
                      options={tpvOptions}
                      iid="tpvSelect"
                      id="react-select-tpvSelect-input"
                      aria-label="Faturamento mensal"
                      name={fieldProps.field.name}
                      {...fieldProps}
                      onBlurCallBack={(option: OptionTypeBase) => {
                        const name = 'revenue';
                        const value = option.value;

                        analytics?.track({
                          event_type: `step ${name} completed`,
                          event_properties: {
                            [`input_${name}`]: value,
                            name: `step ${name} completed`,
                            description:
                              'Evento disparado quando o usuário seleciona o faturamento. Como propriedade será retornada qual faturamento selecionado',
                          },
                        });
                      }}
                    />
                  )}
                </Field>
              </If>

              <p className="text-center text-display-700 paragraph-14">
                Ao continuar, você concorda com nosso{' '}
                <a
                  href="https://docs.stone.com.br/aviso-de-privacidade/"
                  className="text-stone-500 underline cursor-pointer paragraph-14"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="Aviso de Privacidade"
                >
                  Aviso de Privacidade.
                </a>
              </p>

              <Button
                id="tpv-submit"
                disabled={isSmb ? false : isButtonDisabled}
                type="button"
                onClick={() => {
                  props.setFieldValue('channel', 'whatsapp');
                  props.handleSubmit();
                  trackCtaTestAdquira('Continuar pelo WhatsApp');
                }}
                className="gap-8 w-full btn btn-large btn-primary"
              >
                <IconWhatsapp fill="white" className="m-10" />
                Continuar pelo WhatsApp
              </Button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default InboundForm;
