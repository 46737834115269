'use client';

import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { chatIsOpenState } from '@/components/Widget/atoms';
import useStore from '@/store';
import { useInbound } from './inbound';
import { TInboundFormData } from '@/containers/InboundStone/components/InboundFormV2';
import {
  formData as TFormData,
  handleInboundFormSubmit,
} from '@/containers/InboundStone/services';
import { useSetRecoilState } from 'recoil';
import { useMgm } from '@/hooks/useMgm';
import { useRex } from '@/hooks/useRex';
import { AmplitudeContext } from './amplitude';
import { validateIsMGM } from '@/services/validateMgmRendaExtra';
import { useResponsiveQuery } from 'atomic-layout';
import { bffHttp, useHttpClient } from '@/utils/http-client';
import getUtmUrl from '@/utils/getUtmUrl';
import { sendInboundValues } from '@/services/sendInboundValues';
import { formatOnlyNumbers } from '@/utils/masks';
import { translateTpvOption } from '@/utils/translateTpvOption';
import { saveInboundLeads } from '@/services/saveInboundLeads';
import { isMouseInTopArea } from '@/helpers/handleMouseMove';
import { TChannelForm } from '@/types/inbound';
import { useSearchParams } from 'next/navigation';

interface FormInboundProps {
  children: ReactNode;
}

interface FormInboundContextType {
  formData: TFormData;
  setFormData: React.Dispatch<React.SetStateAction<TFormData>>;
  showFinishModal: boolean;
  setShowFinishModal: React.Dispatch<React.SetStateAction<boolean>>;
  defaultChannelSelected: TChannelForm | null;
  initialSubmitInboundForm: (event: TInboundFormData) => Promise<void>;
}

export const FormInbound = createContext<FormInboundContextType>(
  {} as FormInboundContextType,
);

export const useFormInbound = (): FormInboundContextType => {
  const context = useContext(FormInbound);
  if (context === undefined) {
    throw new Error('useFormInbound must be used within a FormInboundProvider');
  }
  return context;
};

export const FormInboundProvider: React.FC<FormInboundProps> = ({
  children,
}) => {
  const searchParams = useSearchParams();
  const { attendanceStatus } = useInbound();
  const [formData, setFormData] = useState<TFormData>({} as TFormData);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [defaultChannelSelected, setDefaultChannelSelected] =
    useState<TChannelForm | null>(null);
  const { analytics } = useContext(AmplitudeContext);
  const setChatIsOpen = useSetRecoilState(chatIsOpenState);
  const [isMgm] = useMgm();
  const isRex = useRex();
  const data = useStore();
  const hasMGMProps = validateIsMGM(data);
  const isMobile = useResponsiveQuery({ to: 'sm' });
  const [showModal, setShowModal] = useState(false);
  const validationIsREXOrMGM = isMgm || isRex || hasMGMProps;

  const httpClient = useHttpClient(bffHttp);

  const initialSubmitInboundForm = async (event: TInboundFormData) => {
    if (searchParams?.get('plan')) {
      event.isPlanPage = true;
    }

    if (attendanceStatus === 'scheduled') {
      event.leadSourceGroup = 'adquira_madrugada';
    }
    const store = data as unknown as Record<string, string>;
    const Alltms = getUtmUrl() as Record<string, string>;

    if (Alltms.hasOwnProperty('coupon')) {
      event.cupom = Alltms.coupon || '';
    }

    if (validationIsREXOrMGM) {
      sendInboundValues(httpClient.fetch, {
        document: event.document,
        full_name: event.name,
        phone: formatOnlyNumbers(event.phone),
        email: event.email,
        tpv: translateTpvOption(event.tpv),
        tracking: {
          coupon: Alltms.coupon || store.coupon,
          utm_medium: Alltms.utm_medium || store.utm_medium,
          utm_source: Alltms.utm_source || store.utm_source,
          utm_campaign: Alltms.utm_campaign || store.utm_campaign,
          utm_content: Alltms.utm_content,
          utm_term: Alltms.utm_term || store.utm_term,
          utm_placement: Alltms.utm_placement,
          fbid: Alltms.fbid,
          gcid: Alltms.gcid,
          client_id: Alltms.client_id,
          user_id: Alltms.user_id,
        },
      });
    }

    if (event.channel === 'chat') {
      setChatIsOpen(true);
    }

    await saveInboundLeads({
      name: event.name,
      email: event.email,
      phone: event.phone,
      document: event.document,
      tpv: event.tpv,
    });

    handleInboundFormSubmit(event);
    setFormData(event);
    setShowFinishModal(true);
  };

  useEffect(() => {
    const alreadyShowed = localStorage.getItem('meetPlansModal');
    if (!alreadyShowed && isMobile) {
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isMobile]);

  useEffect(() => {
    const alreadyShowed = localStorage.getItem('meetPlansModal');

    const handleMouseMove = (e: MouseEvent) => {
      const { clientX, clientY } = e;

      if (!alreadyShowed && isMouseInTopArea(clientX, clientY)) {
        setShowModal(true);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [showModal]);

  useEffect(() => {
    analytics?.trackPageViewed();
  }, [analytics]);

  //TODO: Verificar se há uma maneira melhor de chamar o "refresh" do phonetrack em cada página
  useEffect(() => {
    setTimeout(() => window.phonetrack?.refresh?.(), 1000);
  }, []);

  useEffect(() => {
    if (attendanceStatus === 'scheduled') {
      setDefaultChannelSelected('whatsapp');
    } else {
      setDefaultChannelSelected('chat');
    }
  }, [attendanceStatus]);
  const value: FormInboundContextType = {
    formData,
    setFormData,
    showFinishModal,
    setShowFinishModal,
    defaultChannelSelected,
    initialSubmitInboundForm,
  };

  return <FormInbound.Provider value={value}>{children}</FormInbound.Provider>;
};
