export type twilioStartEngagementParams = {
  tpv?: string;
  name?: string;
  email?: string;
  phone?: string;
  source: string;
  channel: string;
  gaClientId: string;
  gaTrackingId: string;
  departament?: string;
  cupom?: string;
};

export const twilioStartEngagement = async ({
  tpv,
  name,
  email,
  phone,
  source,
  channel,
  gaClientId,
  departament,
  gaTrackingId,
  cupom,
}: twilioStartEngagementParams): Promise<void> => {
  await window.Twilio?.FlexWebChat.Actions.invokeAction('RestartEngagement');

  await window.Twilio?.FlexWebChat.Actions.invokeAction('StartEngagement', {
    formData: {
      tpv,
      source,
      gaClientId,
      departament,
      company: '',
      gaTrackingId,
      friendlyName: name,
      friendlyEmail: email,
      friendlyPhone: phone,
      contactChannel: channel,
      customerType: 'CNPJ',
      cupom,
    },
  });
};
