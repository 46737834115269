import flagsIcons from '@/resources/general/flagsIcons';

export const FlagsList = () => {
  return (
    <div className="grid overflow-y-scroll grid-cols-5 gap-36 h-[50rem]">
      {Object.entries(flagsIcons)
        .sort()
        .map(item => {
          return (
            <div className="flex flex-col justify-center items-center w-full ">
              {item[1]}
              <p>{item[0]}</p>
            </div>
          );
        })}
    </div>
  );
};
