'use client';

import { IPlan } from '@/containers/Plans/components/Plans/planType';
import { getStonePlans } from '@/services/getStonePlans';
import { useRouter, useSearchParams } from 'next/navigation';
import { createContext, useContext, useState } from 'react';
import useStore from '@/store';

interface PlansContextType {
  handleAutoCredSubmit: (planName: string) => void;
  setCouponParam: (coupon: string) => void;
  couponParam: string | undefined;
  plans: IPlan[];
  setPlans: (plans: IPlan[]) => void;
}

export enum PlanIndexEnum {
  essencial = 1,
  essencialPro = 2,
  flexClassical = 3,
}

export const PlansContext = createContext<PlansContextType>(
  {} as PlansContextType,
);

export const usePlans = (): PlansContextType => {
  const context = useContext(PlansContext);

  if (!context) {
    throw new Error('usePlans must be used within PlansContext');
  }
  return context;
};

export const PlansProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const data = useStore();
  const [couponParam, setCouponParam] = useState('');
  const [plans, setPlans] = useState<IPlan[]>([]);

  const isFlexPlan = (planName: string) => {
    return ['Flex', 'Modo Flex'].includes(planName);
  };

  const handleAutoCredSubmit = async (planName: string) => {
    const stonePlans = await getStonePlans(true);
    const plan = planName.replace(/\s/g, '').toLowerCase();

    if (isFlexPlan(planName)) {
      const params = new URLSearchParams(searchParams?.toString());
      params.set('plan', '');

      const newPath = `/adquira?${params.toString()}`;
      router.push(newPath);

      return;
    }

    const findPlan = stonePlans.find(
      (stonePlan: IPlan) =>
        stonePlan.plan_name.replace(/\s/g, '').toLowerCase() === plan,
    );

    const indexOfPlanNameEnum = Object.keys(PlanIndexEnum).indexOf(plan);
    const indexOfPlanEnum = Object.values(PlanIndexEnum)[
      indexOfPlanNameEnum
    ] as number;

    if (!findPlan) return;

    const { offer_id, plan_name } = findPlan;

    data.updateWithIndexData({
      offer_id,
      plan_name,
      planIndex: indexOfPlanEnum,
    });

    const couponAndPlan = `offer_id=${offer_id}${couponParam}`;
    const search = window.location.search.length
      ? `${window.location.search}&${couponAndPlan}`
      : `?${couponAndPlan}`;
    window.location.href = `${process.env.NEW_AUTOCRED_BASE_URL}/dados${search}`;
    return;
  };

  return (
    <>
      <PlansContext.Provider
        value={{
          handleAutoCredSubmit,
          setCouponParam,
          couponParam,
          plans,
          setPlans,
        }}
      >
        <>{children}</>
      </PlansContext.Provider>
    </>
  );
};
