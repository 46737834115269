import IS_SERVER from '../constants/isServer';

const getUrlParams = (): {
  name: string;
  value: string;
}[] => {
  const queryParams: { name: string; value: string }[] = [];

  if (!IS_SERVER) {
    const [, query] = window.location.search.split('?');

    if (query) {
      const fields = query.split('&');

      for (let i = 0; i < fields.length; i += 1) {
        const [key, value] = fields[i].split('=');

        queryParams.push({ name: key, value });
      }
    }
  }

  return queryParams;
};

export { getUrlParams };
