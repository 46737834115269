import { useContext } from 'react';
import { AmplitudeContext } from '@/contexts/amplitude';

export interface CMSEventParams {
  name: string;
  description: string;
}

export interface CMSEventReference {
  section: string;
  cta?: string;
}

export function useAnalytics() {
  const { analytics } = useContext(AmplitudeContext);

  return {
    async sendEvents(events: CMSEventParams[], reference?: CMSEventReference) {
      return events.map(event =>
        analytics?.track({
          event_type: event.name,
          event_properties: {
            name: event.name,
            description: event.description,
            section_reference: reference?.section,
            cta_reference: reference?.cta,
          },
        }),
      );
    },
  };
}
