import AleloFlag from '@/assets/icons/flags/alelo.svg';
import AmexFlag from '@/assets/icons/flags/amex.svg';
import ApplePay from '@/assets/icons/flags/applePay.svg';
import AvancardFlag from '@/assets/icons/flags/avancard.svg';
import BanesFlag from '@/assets/icons/flags/banes.svg';
import BenFlag from '@/assets/icons/flags/ben.svg';
import BiqFlag from '@/assets/icons/flags/biq.svg';
import BnbFlag from '@/assets/icons/flags/bnb.svg';
import CabalFlag from '@/assets/icons/flags/cabal.svg';
import CooperFlag from '@/assets/icons/flags/cooper.svg';
import EloFlag from '@/assets/icons/flags/elo.svg';
import GoodFlag from '@/assets/icons/flags/good.svg';
import GooglePay from '@/assets/icons/flags/googlePay.svg';
import GreenFlag from '@/assets/icons/flags/green.svg';
import HiperFlag from '@/assets/icons/flags/hiper.svg';
import HipercardFlag from '@/assets/icons/flags/hipercard.svg';
import MasterFlag from '@/assets/icons/flags/master.svg';
import PersonalFlag from '@/assets/icons/flags/personal.svg';
import PluxeeFlag from '@/assets/icons/flags/pluxee.svg';
import SamsungPay from '@/assets/icons/flags/samsungPay.svg';
import SenffFlag from '@/assets/icons/flags/senff.svg';
import TicketFlag from '@/assets/icons/flags/ticket.svg';
import TrioFlag from '@/assets/icons/flags/trio.svg';
import UnionPayFlag from '@/assets/icons/flags/unionpay.svg';
import UpFlag from '@/assets/icons/flags/up.svg';
import ValeFlag from '@/assets/icons/flags/vale.svg';
import VerdCardFlag from '@/assets/icons/flags/verdcard.svg';
import VerdeFlag from '@/assets/icons/flags/verde.svg';
import VisaFlag from '@/assets/icons/flags/visa.svg';
import VrFlag from '@/assets/icons/flags/vr.svg';

export const flagsNames = [
  'alelo',
  'amex',
  'avancard',
  'banes',
  'ben',
  'biq',
  'cabal',
  'cooper',
  'elo',
  'green',
  'hiper',
  'hipercard',
  'master',
  'senff',
  'pluxee',
  'ticket',
  'unionpay',
  'up',
  'vale',
  'verdcard',
  'verde',
  'visa',
  'vr',
  'bnb',
  'good',
  'personal',
  'trio',
  'googlePay',
  'samsungPay',
  'applePay',
] as const;

export type TFlagName = (typeof flagsNames)[number];

export type TFlagsIcons = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [key in TFlagName | string]: JSX.Element;
};

const flagsIcons: TFlagsIcons = {
  alelo: <AleloFlag />,
  amex: <AmexFlag />,
  avancard: <AvancardFlag />,
  banes: <BanesFlag />,
  ben: <BenFlag />,
  biq: <BiqFlag />,
  cabal: <CabalFlag />,
  cooper: <CooperFlag />,
  elo: <EloFlag />,
  green: <GreenFlag />,
  hiper: <HiperFlag />,
  hipercard: <HipercardFlag />,
  master: <MasterFlag />,
  senff: <SenffFlag />,
  pluxee: <PluxeeFlag />,
  ticket: <TicketFlag />,
  unionpay: <UnionPayFlag />,
  up: <UpFlag />,
  vale: <ValeFlag />,
  verdcard: <VerdCardFlag />,
  verde: <VerdeFlag />,
  visa: <VisaFlag />,
  vr: <VrFlag />,
  bnb: <BnbFlag />,
  good: <GoodFlag />,
  personal: <PersonalFlag />,
  trio: <TrioFlag />,
  googlePay: <GooglePay />,
  samsungPay: <SamsungPay />,
  applePay: <ApplePay />,
};

export default flagsIcons;
