'use client';

import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type ClientOnlyPortalProps = {
  children: React.ReactNode;
  selector: string;
};

const ClientOnlyPortal: React.FC<ClientOnlyPortalProps> = ({
  children,
  selector,
}) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const element = document.querySelector(selector);
    if (element) {
      ref.current = element;

      setMounted(true);
    }
  }, [selector]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export default ClientOnlyPortal;
