/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CATEGORIES, CHANNEL_LABELS } from '@/constants/analytics';
import { EVENTS } from '@/containers/InboundStone/constants/tagManager';
import ReactGA from 'react-ga';

import { GA_SETTINGS } from '../constants/analyticsSettings';

let GA_INITIALIZED = false;

const GAinitialize = async () => {
  if (GA_INITIALIZED) return;

  GA_INITIALIZED = true;

  const { UACode, debug, gaOptions } = GA_SETTINGS;

  ReactGA.initialize(UACode, {
    debug,
    gaOptions,
  });

  ReactGA.ga('require', 'linker');
  ReactGA.ga('linker:autoLink', ['stone.com.br', 'ton.com.br']);
};

export const handlePhoneOnInboundSiteClick = page => {
  ReactGA.event({
    category: CATEGORIES.INBOUND_LEAD,
    action: CHANNEL_LABELS.PHONE,
    label: `Página ${page}`,
  });

  window.dataLayer?.push({
    event: EVENTS.INBOUND_CALLING,
    channel: CHANNEL_LABELS.PHONE,
  });
};

export const handlePhoneOnInboundHeaderClick = () => {
  ReactGA.event({
    category: CATEGORIES.INBOUND_LEAD,
    action: CHANNEL_LABELS.PHONE,
    label: 'Menu',
  });

  window.dataLayer?.push({
    event: EVENTS.INBOUND_CALLING,
    channel: CHANNEL_LABELS.PHONE,
  });
};

export const handlePhoneOnRCSiteClick = page => {
  ReactGA.event({
    category: CATEGORIES.RC_CONTACT,
    action: CHANNEL_LABELS.PHONE,
    label: `Página ${page}`,
  });

  window.dataLayer?.push({
    event: EVENTS.RC_CALLING,
    channel: CHANNEL_LABELS.PHONE,
  });
};

export const handlePhoneOnRCHeaderClick = () => {
  ReactGA.event({
    category: CATEGORIES.RC_CONTACT,
    action: CHANNEL_LABELS.PHONE,
    label: 'Menu',
  });

  window.dataLayer?.push({
    event: EVENTS.RC_CALLING,
    channel: CHANNEL_LABELS.PHONE,
  });
};

export const handleWppOnRCHeaderClick = () => {
  ReactGA.event({
    category: CATEGORIES.RC_CONTACT,
    action: CHANNEL_LABELS.WHASTSAPP,
    label: 'Menu',
  });

  window.dataLayer?.push({
    event: EVENTS.RC_CALLING,
    channel: CHANNEL_LABELS.WHASTSAPP,
  });
};

const GApageView = path => ReactGA.pageview(path);

const GAsendEvent = (category, action, label = '', value = 0) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

const GAsendEventWithCallback = (
  callback,
  category,
  action,
  label = '',
  value = 0,
) => {
  ReactGA.outboundLink(
    {
      category,
      action,
      label,
      value,
    },
    () => callback(),
  );
};

const getGaClientId = () => {
  const cookie = {};
  document.cookie.split(';').forEach(function (el) {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie['_ga'].substring(6);
};

const getGatrackingId = () => {
  const cookie = {};
  document.cookie.split(';').forEach(function (el) {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie['_ga_G346RH758C'].substring(6).split('.')[0];
};

const getTranckerInfo = async () => {
  let gaClientId = '';
  let gaTrackingId = '';

  await ReactGA.ga(() => {
    gaClientId = getGaClientId();
    gaTrackingId = getGatrackingId();
  });

  return { gaClientId, gaTrackingId };
};

export {
  GAinitialize,
  GAsendEvent,
  GAsendEventWithCallback,
  GApageView,
  getTranckerInfo,
};
