'use client';

import { useReportWebVitals } from 'next/web-vitals';
import ReactGA from 'react-ga';
import { GAinitialize } from '@/utils/Analytics';

// TODO(@gabrieldissotti) Analisar a possibilidade de remover esse código: Acreditamos que disparar esses eventos de web vitals não faz mais sentido, pois essa propriedade está desativada... O que alimenta os dashboards de web vitals é o próprio chrome segundo o phil e vyctor...
export function WebVitals() {
  useReportWebVitals(async metric => {
    await GAinitialize();

    const webVitalsMetrics = ['TTFB', 'FCP', 'LCP', 'FID', 'CLS', 'TBT', 'PNE'];

    ReactGA.event({
      action: String(metric.name),
      category: webVitalsMetrics.includes(metric.name)
        ? 'Web Vitals'
        : 'Next.js custom metric',
      value: Math.round(
        metric.name === 'CLS' ? metric.value * 1000 : metric.value,
      ),
      label: metric.id,
      nonInteraction: true,
    });
  });

  return null;
}
