const getUtmUrl = function () {
  const self = window.location.toString();
  const querystring = self.split('?');
  const utmObj = {};

  if (querystring.length > 1) {
    var pairs = querystring[1].split('&');
    for (const i in pairs) {
      var keyval = pairs[i].split('=');
      utmObj[keyval[0]] = keyval[1];
    }
  }

  return utmObj;
};

export default getUtmUrl;
