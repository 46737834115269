import dynamic from 'next/dynamic';

const ObjectsIcons2x = {
  'office-2x': dynamic(() => import('./Office')),
  'date-clock-2x': dynamic(() => import('./DateClock')),
  'date-calendar-2x': dynamic(() => import('./DateCalendar')),
  'accelerator-2x': dynamic(() => import('./Accelerator')),
  'e-cart-2x': dynamic(() => import('./ECart')),
  'auto-bus-2x': dynamic(() => import('./AutoBus')),
  'book-open-2x': dynamic(() => import('./BookOpen')),
};

export default ObjectsIcons2x;
