import { ACTIONS, CATEGORIES, CHANNEL_LABELS } from '@/constants/analytics';
import { createStudioExecution } from '@/services/createStudioExecution';
import { TChannel } from '@/types/inbound';
import { GAsendEvent, getTranckerInfo } from '@/utils/Analytics';
import {
  twilioStartEngagement,
  twilioStartEngagementParams,
} from '@/utils/twilio';

import { EVENTS } from '../constants/tagManager';
import { createLead } from './lead';

type requestHandler = Omit<twilioStartEngagementParams, 'channel'>;

export const handleWhatsappRequest = (data: requestHandler): Promise<void> =>
  createStudioExecution({
    ...data,
    channel: 'whatsapp',
  });

export const handleChatRequest = (data: requestHandler): void => {
  twilioStartEngagement({
    ...data,
    channel: 'chat',
  });
};

type handlerFunctionBasedOnChannelType = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [key in TChannel]: (data: requestHandler) => Promise<void> | void;
};

const handlerFunctionBasedOnChannel: handlerFunctionBasedOnChannelType = {
  chat: handleChatRequest,
  whatsapp: handleWhatsappRequest,
  'whatsapp-out-business-hours': handleWhatsappRequest,
  'phone-c2c': () => undefined,
  mobile: () => undefined,
  phone: () => undefined,
} as const;

export type formData = {
  name: string;
  tpv: string;
  email: string;
  phone: string;
  channel: TChannel;
  leadSourceGroup?: string;
  isPlanPage?: boolean;
  hasSmbCnpj?: boolean;
  isSmbPage?: boolean;
};

export const handleInboundFormSubmit = async (
  data: formData,
): Promise<void> => {
  const { tpv, name, leadSourceGroup, isPlanPage, hasSmbCnpj, isSmbPage } =
    data;
  let source = 'inbound_form';

  if (isPlanPage) {
    source = 'lead_autocred';
  }

  if (isSmbPage && !hasSmbCnpj) {
    source = 'inbound_smb';
  }

  let { channel } = data;
  const { gaClientId, gaTrackingId } = await getTranckerInfo();

  if (!!leadSourceGroup) {
    channel = 'whatsapp-out-business-hours';
  }

  const functionHandler =
    handlerFunctionBasedOnChannel[channel] || (() => undefined);

  window.dataLayer?.push({
    event: EVENTS.INBOUND_FORM_SUBMITTED,
    channel,
    tpv,
  });

  const channelLabel =
    channel === 'chat'
      ? CHANNEL_LABELS.CHAT
      : channel === 'whatsapp'
      ? CHANNEL_LABELS.WHASTSAPP
      : channel === 'whatsapp-out-business-hours'
      ? CHANNEL_LABELS.WHASTSAPP_OUT_BUSINESS_HOURS
      : CHANNEL_LABELS.WE_CALL;

  GAsendEvent(CATEGORIES.INBOUND_LEAD, channelLabel, tpv);
  GAsendEvent(CATEGORIES.INBOUND_FORM, ACTIONS.ADQUIRA, channelLabel);

  const formattedData: formData = { ...data, name: name.trim() };

  if (channel === 'phone-c2c') {
    createLead({
      ...formattedData,
      channel: 'phone',
      gaClientId,
      gaTrackingId,
      source,
    });
  }

  return functionHandler({
    ...formattedData,
    gaClientId,
    gaTrackingId,
    source,
  });
};

export interface IActiveChatFormSubmitData extends formData {
  source: string;
}
export const handleActiveChatFormSubmit = async (
  data: IActiveChatFormSubmitData,
): Promise<void> => {
  const { gaClientId, gaTrackingId } = await getTranckerInfo();
  const functionHandler =
    handlerFunctionBasedOnChannel[data.channel] || (() => undefined);

  let channelLabel;
  switch (data.channel) {
    case 'chat':
      channelLabel = CHANNEL_LABELS.CHAT;
      break;
    case 'whatsapp':
      channelLabel = CHANNEL_LABELS.WHASTSAPP;
      break;
    case 'whatsapp-out-business-hours':
      channelLabel = CHANNEL_LABELS.WHASTSAPP_OUT_BUSINESS_HOURS;
      break;
    default:
      channelLabel = CHANNEL_LABELS.WE_CALL;
      break;
  }

  window.dataLayer?.push({
    event: EVENTS.INBOUND_FORM_SUBMITTED,
    channel: channelLabel,
    tpv: data.tpv,
  });

  GAsendEvent(CATEGORIES.INBOUND_LEAD, channelLabel, data.tpv);
  GAsendEvent(CATEGORIES.INBOUND_FORM, ACTIONS.ADQUIRA, channelLabel);

  return functionHandler({
    ...data,
    gaClientId,
    gaTrackingId,
  });
};
