import IS_SERVER from '@/constants/isServer';

import { isInternalUrl } from './url';

const buildUrlUtm = (link: string): string => {
  const isExternal = !isInternalUrl(link);

  const queryData: { key: string; value: string }[] = IS_SERVER
    ? []
    : JSON.parse(sessionStorage.getItem('queryData') || '[]');

  if (isExternal && queryData && queryData.length > 0) {
    const url = new URL(link);

    queryData.forEach(({ key, value }) => {
      url.searchParams.append(key, value);
    });

    return url.toString();
  }

  return link;
};

export default buildUrlUtm;
