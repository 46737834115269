import dynamic from 'next/dynamic';

const SignalsIcons1x = {
  'signal-percent': dynamic(() => import('./SignalPercent')),
  check: dynamic(() => import('./Check')),
  'check-circle': dynamic(() => import('./CheckCircle')),
  'form-star': dynamic(() => import('./FormStar')),
};

export default SignalsIcons1x;
